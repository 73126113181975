import React,{ useState,useEffect } from 'react';
import { useFormik } from 'formik';
import { CircularProgress } from "@mui/material";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select'; 
import axios from 'axios';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import TopbarSearch from './TopbarSearch'; 
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
const PaymentCC = () => {
  const [amount, setAmount] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const navigate = useNavigate();
  const [responsebook, setResponsebook] = useState(null);
  const [enc, setEnc] = useState("");
  const [acc, setAcc] = useState("");
  const [basefare, setBasefare] = useState(0.00);
  const [servicefare, setServicefare] = useState(0.00);
  const ob11=localStorage.getItem('flightchargeOb');
  const prob11=localStorage.getItem('price');
  const ib11=localStorage.getItem('flightchargeIb');
  const prib11=localStorage.getItem('priceIb');

  const totalprice11=parseFloat(prob11)+parseFloat(prib11);
  const grandtotal=localStorage.getItem('grandtotal');
  const [totalfare, setTotalfare] = useState(grandtotal);
  const [useremail, setUseremail] = useState(sessionStorage.getItem('emailll'));
  let passString=sessionStorage.getItem('passString');
  // const handlePayment = async () => {
  //   alert("ss");
  //   // Implement payment request here
  // };
  useEffect(() => {
    // Check if enc and acc are available
    if (enc && acc) {
    // alert(enc);
    // alert(acc);
      const form = document.createElement('form');
      form.method = 'post';
      form.name = 'redirect';
      form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

      // Create hidden input fields for enc and acc
      const inputEnc = document.createElement('input');
      inputEnc.type = 'hidden';
      inputEnc.name = 'encRequest';
      inputEnc.value = enc;

      const inputAcc = document.createElement('input');
      inputAcc.type = 'hidden';
      inputAcc.name = 'access_code';
      inputAcc.value = acc;

      // Append the input fields to the form
      form.appendChild(inputEnc);
      form.appendChild(inputAcc); 

      // Append the form to the body and submit it
      document.body.appendChild(form);
      form.submit();
    }
  }, [enc, acc]);
	useEffect(() => {
		function getData1() {
      const ob1=localStorage.getItem('flightchargeOb');
      const prob1=localStorage.getItem('price');
      const ib1=localStorage.getItem('flightchargeIb');
      const prib1=localStorage.getItem('priceIb');
      let baseob1=0;
      let totpriceob1=0;
      let baseib1=0;
      let totpriceib1=0;
      if(ob1)
      {baseob1=ob1;}
      if(ib1)
      {baseib1=ib1;}
      if(prob1)
      {totpriceob1=prob1;}
      if(prib1)
      {totpriceib1=prib1;}
      let totalbasefare=parseFloat(baseob1)+parseFloat(baseib1);
      let totalprice=parseFloat(totpriceob1)+parseFloat(totpriceib1);
      let serviceprice=parseFloat(totalprice)-parseFloat(totalbasefare);
      setBasefare(totalbasefare);
      setServicefare(serviceprice);
      setTotalfare(totalprice);
			if(localStorage.getItem('useremail')&&localStorage.getItem('tokenValue'))
			{
			const useremail1=localStorage.getItem('useremail');
				setUseremail(useremail1);
			
			}
			else{
				setUseremail('');	
			}
		  }
		  getData1();
	},[]) ;
  const validationSchema = yup.object({
    amount:yup
      .string()
      .required('Required'),
   billing_name: yup
      .string()
      .max(20, 'Must be 20 characters or less') 
      .required('Required'),
    billing_tel: yup
      .string()
      .min(10, 'Must be at least 10 characters')
      .required('Required'),
    billing_email: yup
      .string()
      .email('Invalid email')
      .required('Required'),
    billing_address: yup
      .string()
      .max(20, 'Must be 20 characters or less')
      .required('Required'), 
    billing_country: yup
      .string()
      .required('Required'),
    billing_state: yup
      .string()
      .required('Required'),
     billing_city: yup
    .string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'), 
   
    billing_zip: yup
      .string()
      .required('Required'),

  });
  const initialValues = {
    amount:totalfare,
    billing_name : '',
    billing_tel:'',
    billing_email:useremail,
    billing_address : '',
    billing_country:'India',
    billing_state: '',
    billing_city:'',
    
    billing_zip:'',
   
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
  onSubmit: async (values) => {
    console.log("In submit function"); // Add this line
    const data=
                    {
                     
                      "amount":totalfare,
                      "billing_name": values.billing_name,
                      "billing_address": values.billing_address,
                      "billing_city": values.billing_city,
                      "billing_state":values.billing_state,
                      "billing_zip": values.billing_zip,
                      "billing_email": values.billing_email,
                      "billing_country": values.billing_country,
                      "billing_tel": values.billing_tel,
                    };
            
//console.log(JSON.stringify(data));
                
        try {
                    const response = await axios.post('https://b2b.travelxpo.in/api/ccav-request', data);
                     //console.log(response.data);
                      
                      console.log(response.data.encrypted_data);
                        setEnc(response.data.encrypted_data);
                        setAcc(response.data.access_code);
                       
                    } catch (error) {
                    // Handle errors
                    console.error(error);
                    }
    // Rest of your code
  }
})

  return (
   <div>
    <TopbarSearch />
<section class="bg-white"> 
  <div className="container mb-5">
    <div className="row detailsviewform">
      <div className="col-lg-9 ">
        <div className="row">
                <div className="col-lg-12 detailsview ">
                  <div className="card" style={{display:"none"}}>
                      <div className="card-body">
                        <h4>TICKET DETAILS</h4>
                      </div>
                      <div className="cardsection">
                        <div className="row">
                          <div className="col-lg-6 col-4">
                            <p>Kochi - Mumbai</p>
                          </div>
                          <div className="col-lg-3 col-6 text-end linkssec">
                            <p>Baggage and fare rules</p>
                          </div>
                          <div className="col-lg-3 text-end">
                            <button className="btn btn-dark"> Partally Refundable  </button>
                          </div>
                        </div>
                      </div>
                  </div>
               </div>
          </div>
     
    

     <form  onSubmit={formik.handleSubmit}>
      
     
              <div className="row">
                      <div className="col-lg-12 detailsview ">
                        <div className="card">
                            <div className="card-body">
                              <h4>BILLING ADDRESS</h4>
                            </div>
                            <div className="cardsection">
                              <div className="row">
                              <div className="col-lg-6 col-4">
                                  <label>Amount with GST</label><br />
                                  <TextField
                                        id="amount"
                                        name="amount"
                                        className="formcontrols"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                    />
                                </div>
                                <div className="col-lg-6 col-4">
                                  <label>Customer Name</label><br />
                                  <TextField
                                        id="billing_name"
                                        name="billing_name"
                                        className="formcontrols"
                                        value={formik.values.billing_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_name && Boolean(formik.errors.billing_name)}
                                        helperText={formik.touched.billing_name && formik.errors.billing_name}
                                    />
                                </div>
                               <div className="col-lg-6 col-4">
                                  <label>Mobile Number</label><br />
                                  <TextField
                                        id="billing_tel"
                                        name="billing_tel"
                                        className="formcontrols"
                                        value={formik.values.billing_tel}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_tel && Boolean(formik.errors.billing_tel)}
                                        helperText={formik.touched.billing_tel && formik.errors.billing_tel}
                                    />
                                </div>
                                <div className="col-lg-6 col-4">
                                <label>Email ID</label><br />
                                <TextField
                                        id="billing_email"
                                        name="billing_email"
                                        className="formcontrols"
                                        value={formik.values.billing_email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_email && Boolean(formik.errors.billing_email)}
                                        helperText={formik.touched.billing_email && formik.errors.billing_email}
                                    />
                                </div>
                               <div className="col-lg-6 col-4">
                                <label>Address</label><br />
                                <TextField
                                        id="billing_address"
                                        name="billing_address"
                                        className="formcontrols"
                                        value={formik.values.billing_address}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_address && Boolean(formik.errors.billing_address)}
                                        helperText={formik.touched.billing_address && formik.errors.billing_address}
                                    />
                                </div>
                                  <div className="col-lg-6 col-4">
                                <label>Country</label><br />
                                <TextField
                                        id="billing_country"
                                        name="billing_country"
                                        className="formcontrols"
                                        value={formik.values.billing_country}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_country && Boolean(formik.errors.billing_country)}
                                        helperText={formik.touched.billing_country && formik.errors.billing_country}
                                    />
                                </div>
                                <div className="col-lg-6 col-4">
                                <label>State</label><br />
                                <TextField
                                        id="billing_state"
                                        name="billing_state"
                                        className="formcontrols"
                                        value={formik.values.billing_state}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_state && Boolean(formik.errors.billing_state)}
                                        helperText={formik.touched.billing_state && formik.errors.billing_state}
                                    />
                                </div>
                                
                               
                                 <div className="col-lg-6 col-4">
                                <label>District</label><br />
                                <TextField
                                        id="billing_city"
                                        name="billing_city"
                                        className="formcontrols"
                                        value={formik.values.billing_city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_city && Boolean(formik.errors.billing_city)}
                                        helperText={formik.touched.billing_city && formik.errors.billing_city}
                                    />
                               </div> 
                                <div className="col-lg-6 col-4">
                                <label>Pincode</label><br />
                                <TextField
                                        id="billing_zip"
                                        name="billing_zip"
                                        className="formcontrols"
                                        value={formik.values.billing_zip}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.billing_zip && Boolean(formik.errors.billing_zip)}
                                        helperText={formik.touched.billing_zip && formik.errors.billing_zip}
                                    />
                                </div> 
                                
                                <div className="col-lg-4 text-end float-end">
                                  <br></br>
                                <Button type="Submit"  className="submitebtn pt-3"  variant="contained" color="primary">
                                        Check Out{formik.isSubmitting ? <CircularProgress size={24} /> : ' >>>' }
                                </Button>
                                </div>
                              </div>
                            </div>
                        </div>
                     </div>
                </div>
               
        </form> 
        </div>
      <div className="col-lg-3">
        <div className="asidesection bg-white">
          <div className="card" style={{display:"none"}}>
              <div className="card-body" >
                <h4>FARE SUMMARY </h4>
                <p>{passString}	</p>
              </div>
                <div className="pricedetails">
                  <div className="price">
                    <table className="table pricelisttable bg-white table-borderless">
                    <tbody>
                      <tr>
                        <td>Base fare + Tax</td>
                        <td className="text-right">{basefare}</td>
                      </tr>
                      
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Other charges</td>
                        <td className="text-right">{servicefare}</td>
                      </tr>
                      {/* <tr>
                        <td style={{fontSize: "10px",textAlign: "justify",color: "#000"}}>make a difference by contributing 10 to support & join us in promoting a culture to substainable travel</td>
                        <td> <input className="me-3 mt-3" type="checkbox" name="checkprice" /></td>
                      </tr> */}
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><h4>Grand Total</h4></td>
                        <td><h3>{totalfare}</h3></td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
          </div>
          {/* <div className="card mt-4">
            <div className="card-body">
              <h4>TRAVEXPRO OFFERS</h4>
            </div>
            <div className="pricedetails">
              <div className="price">
                <table className="table pricelisttable bg-white table-borderless">
                <tbody>
                  <tr>
                    <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                    <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                    <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                  </tr>
                  <tr>
                    <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                    <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                    <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                  </tr>
                  <tr>
                    <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                    <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                    <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                  </tr>
                  
                </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-body discount">
              <h4>Apply Discount</h4>
              <p> Have a discount / promo code to redeem</p>
            </div>
            <div className="discountdetails">
              <div className="row">
                <div className="col-lg-9">
                  <input type="text" className="form-control" placeholder="Promo Code" name="discount" />
                </div>
                <div className="col-lg-3">
                  <button className="btn btnapply">Apply</button>
                </div>	
              </div>	
            </div>
          </div> */}
        </div>
      </div>
    </div>
    
  </div>
  
  </section>
    <footer>
<div class="container">
<hr />
		<div class="row pt-2">
			<div class="col-lg-3 col-sm-6">
			<h3>Contact Us</h3>
				<ul>
					<li><a href="">Customer Support</a></li>
					<li><a href="">Service Guarantee</a></li>
					<li><a href="">Website Feedback</a></li>
				</ul>
			</div>
			<div class="col-lg-3 col-sm-6">
			<h3>About Us</h3>
				<ul>
					<li><a href="">About Travexpo</a></li>
					<li><a href="">News</a></li>
					<li><a href="">Careers</a></li>
					<li><a href="">Travel Guide</a></li>
					<li><a href="">Terms & Conditions</a></li>
					<li><a href="">Travel Insurance</a></li>
					<li><a href="">Privacy Statement</a></li>
					<li><a href="">FAQ</a></li>
				</ul>
			</div>
			<div class="col-lg-3 col-sm-6">
			<h3>Other Services</h3>
			<ul>
					<li><a href="">Investor Relations</a></li>
					<li><a href="">All Hotels</a></li>
					<li><a href="">Affiliate Program</a></li>
					<li><a href="">Travel Guide</a></li>
					<li><a href="">Terms & Conditions</a></li>
					<li><a href="">List My Hotel</a></li>
					<li><a href="">Privacy Statement</a></li>
					<li><a href="">Become a Supplier</a></li>
				</ul>
			</div>
			<div class="col-lg-3 col-sm-6">
			<h3>Payment Methods</h3>
			<div class="news">
				<div class="image">
					<img src="http://dummyimage.com/70x70/f0f0f0/333" alt="" />
				</div>
				<div class="text">
					<h5>Tokyo</h5>
					<p>Dreamy bed and delicious breakfast Entire home/apt · 1 bed · 3 guests · Business</p>
				</div>
			</div>
			<div class="news">
				<div class="image">
					<img src="http://dummyimage.com/70x70/f0f0f0/333" alt="" />
				</div>
				<div class="text">
					<h5>Tokyo</h5>
					<p>Dreamy bed and delicious breakfast Entire home/apt · 1 bed · 3 guests · Business</p>
				</div>
			</div>
			</div>
		</div>
	</div>
</footer>
	{/*next div  footer*/}
	<div class="footer-bto">
       <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <div class="logo"><img src="images/payment.png" alt="" /></div>
                </div>
                <div class="col-md-2">
                    <div class="socil">
                        <ul>
                            <li><a href=""><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href=""><i class="fab fa-twitter"></i></a></li>
                            <li><a href=""><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href=""><i class="fab fa-pinterest-p"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-5">
                    <p>Copyright 2017  Travelden| All rights reserved.</p>
                </div>
            </div>
       </div>
    </div>

    </div>

  )
}

export default PaymentCC