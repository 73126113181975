import React,{ useState,useEffect } from 'react';
import { useFormik } from 'formik';
import { CircularProgress } from "@mui/material";
import * as yup from 'yup'; 
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import moment from 'moment/moment';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import TopbarSearch from './TopbarSearch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const CustomerInfoIBOB = () => {
    const navigate = useNavigate();
    const [adult,setAdult]=useState(0);
    const [child,setChild]=useState(0);
    const [infant,setInfant]=useState(0);
    const [totalpass,setTotalPass]=useState(0);
    const [btnhead,setBtnhead]=useState("Add New");
   const [dobtext,setDobtext]=useState("Age above 12");
   const [responsebook, setResponsebook] = useState(null);
   const [value, setValue] = useState(null);
   const [isDivVisible, setIsDivVisible] = useState(false);
   const [refundable, setRefumdable] = useState("Refundable");
   let balance=sessionStorage.getItem('Balance');

   let markuppercent = sessionStorage.getItem('Markuppercent');
   let markup = sessionStorage.getItem('Markup');
   const [flightcharge,setFlightcharge]=useState(0);
   const [flightchargeIb,setFlightchargeIb]=useState(0);
   let agentId=sessionStorage.getItem('agentId');
   let branchId=sessionStorage.getItem('branchId');

   const currentDate = new Date();
   let mxDate = new Date();
   mxDate.setFullYear(currentDate.getFullYear() - 12);
   let mnDate = new Date();
   mnDate.setFullYear(currentDate.getFullYear() - 112);
   const [minDate, setMinDate] = useState(mnDate);
   const [maxDate, setMaxDate] = useState(mxDate);
   const [data,setData]=useState();
   const [dataIb,setDataIb]=useState();

   let passeng =[];
  const [passengers,setPassengers] =useState(passeng);
  const [passengersIb,setPassengersIb] =useState(passeng);
  const [heading,setHeading]=useState("Adult Passenger");
  const [resultindex,setResultindex]=useState('');
  const [resultindexIb,setResultindexIb]=useState('');
  const [traceId,setTraceId]=useState('');
  const [priceOb,setPriceOb]=useState(0);
  const [priceIb,setPriceIb]=useState(0);

  const [passenStr, setPassenStr] = useState('');
  const [fare, setFare] = useState();
  const [farequote, setFarequote] = useState();
  const [fareIb, setFareIb] = useState();
  const [farequoteIb, setFarequoteIb] = useState();
  const [documentDetails, setDocumentDetails] = useState([]);
  const [ssrmeal, setSSRmeal] = useState();
  const [ssrbag, setSSRbag] = useState();
  const [ssrseat, setSSRseat] = useState();
  const [ssrmealIb, setSSRmealIb] = useState();
  const [ssrbagIb, setSSRbagIb] = useState();
  const [ssrseatIb, setSSRseatIb] = useState();

  const [submitbtn, setSubmitbtn] = useState({ display: 'block' });
  const [adultss1, setAdultss1] = useState(0);
  const [childss1, setChildss1] = useState(0);
  const [infants1, setInfants1] = useState(0);
  const [ordstr, setOrdstr] = useState(0);
  const [useremail, setUseremail] = useState('');
  const [basefare, setBasefare] = useState(0.00);
  const [servicefare, setServicefare] = useState(0.00);
  const [totalfare, setTotalfare] = useState(0.00);
  const [basefareIb, setBasefareIb] = useState(0.00);
  const [totalfareIb, setTotalfareIb] = useState(0.00);
  let passString=sessionStorage.getItem('passString');
  const [email, setEmail] = useState('');
  const [ccode, setCcode] = useState('+91');
  const [mobile, setMobile] = useState('');
  const [country, setCountry] = useState('IN');
  const [city, setCity] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [editView, setEditView] = useState("none");
  const [editedFirstName, setEditedFirstName] = useState("");
  const [editedLastName, setEditedLastName] = useState("");
  const [editedDateOfBirth, setEditedDateOfBirth] = useState("");
  const [editedTitle, setEditedTitle] = useState("");
  const [editedPPNumber, setEditedPPNumber] = useState("");
  const [editedPPExpireDate, setEditedPPExpireDate] = useState("");
  const [editedPaxType, setEditedPaxType] = useState(0);
  const [editedPassType, setEditedPassType] = useState(" Adult ");
  const [isLCC, setIsLCC] = useState(true);
  const [resultFareType, setResultFareType] = useState("RegularFare");
  const [isLCCIb, setIsLCCIb] = useState(true);
  const [totalMeal, setTotalMeal] = useState(0.00);
  const [totalBags, setTotalBags] = useState(0.00);
  const [totalMealIb, setTotalMealIb] = useState(0.00);
  const [totalBagsIb, setTotalBagsIb] = useState(0.00);
  const [selectedMealDynamic, setSelectedMealDynamic] = useState([]);
  const [selectedBagDynamic, setSelectedBagDynamic] = useState([]);
  const [selectedMealDynamicIb, setSelectedMealDynamicIb] = useState([]);
  const [selectedBagDynamicIb, setSelectedBagDynamicIb] = useState([]);
  const [editedMealDynamic, setEditedMealDynamic] = useState([]);
  const [editedBagDynamic, setEditedBagDynamic] = useState([]);
  const [editedMealDynamicIb, setEditedMealDynamicIb] = useState([]);
  const [editedBagDynamicIb, setEditedBagDynamicIb] = useState([]);
  const [editedFare, setEditedFare] = useState([]);
  const [editedFareIb, setEditedFareIb] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0.00);
  const [agentBalance, setAgentBalance] = useState(0.00);
  const [prevent, setPrevent] = useState(1);

   const [responsebookreturn, setResponsebookreturn] = useState(null);
  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

   const parseHTMLString = (htmlString) => {
       const parser = new DOMParser();
       const parsedDocument = parser.parseFromString(htmlString, 'text/html');
       return parsedDocument.body.textContent;
     };
   //mnDate.setDate(currentDate.getDate() - 729);
   const today = new Date();
   const twelveYearsAgo = new Date();
   twelveYearsAgo.setFullYear(today.getFullYear() - 12);
   const twoYearsAgo = new Date();
   twoYearsAgo.setFullYear(today.getFullYear() - 2);
   const tenYearsAfter = new Date();
   tenYearsAfter.setFullYear(today.getFullYear() + 10);
   const hundredBack=new Date();
   hundredBack.setFullYear(today.getFullYear() - 100);
   const [minDt,setMinDt]=useState(hundredBack);
   const [maxDt,setMaxDt]=useState(twelveYearsAgo);
   const [dCount,setDCount]=useState(100);

   const [adultFare, setAdultFare] = useState(null);
   const [childFare, setChildFare] = useState(null);
   const [infantFare, setInfantFare] = useState(null);
   const [adultFareIb, setAdultFareIb] = useState(null);
   const [childFareIb, setChildFareIb] = useState(null);
   const [infantFareIb, setInfantFareIb] = useState(null);

   const setEditedDateOfBirthFun = (date)=>
   {
     const dd=moment(date).format('YYYY-MM-DD');
     setEditedDateOfBirth(dd);
     }
   const setEditedPPExpireDateFun = (date)=>
   {
     const dd=moment(date).format('YYYY-MM-DD');
     setEditedPPExpireDate(dd);
     }
   useEffect(() => {
       let paass=[];
       setPassengers(paass);
       setPassengersIb(paass);
       setBtnhead("Add New");
       const    adultss = localStorage.getItem('adultCount');
       const   childss = localStorage.getItem('childCount');
       const  infants = localStorage.getItem('infantCount'); 
       
       setAdultss1(adultss);
     setChildss1(childss);
    setInfants1(infants); 
 
       const resultindex1 = localStorage.getItem('resultindex');
       const traceId1 = localStorage.getItem('traceId');
       const priceOb1 = localStorage.getItem('price');
       const storedValue = localStorage.getItem('tokenValue');
       const fightcharge1=localStorage.getItem('flightchargeOb');
       const fightcharge2=localStorage.getItem('flightchargeIb');
       const useremail1=localStorage.getItem('useremail');
       const resultindex2 = localStorage.getItem('resultindex_r');
       const priceIb1 = localStorage.getItem('priceIb');
		setUseremail(useremail1);
       if (adultss>0) {
         setAdult(adultss);
        // adultss1 = adultss;
     }
     if (childss>0) {
         setChild(childss);
        // childss1 = childss;
     }
     if (infants>0) {
         setInfant(infants);
       //  infants1 = infants;
     }
     if (adultss==0 && childss>0) {
      setMaxDt(twoYearsAgo);
      setMinDt(twelveYearsAgo);
      setDCount(10);
      setDobtext("Between 2-12 years");
     // adultss1 = adultss;
  }
      // console.log(storedValue);
       if (storedValue) {
         setValue(storedValue);
       }
       if (resultindex1) {
           setResultindex(resultindex1);
         }
       if (traceId1) {
           setTraceId(traceId1);
         }
     if(priceOb1)
     {
       setPriceOb(priceOb1);
     }
     if (priceIb1) {
        setPriceIb(priceIb1);
      }
      if (resultindex2) {
        setResultindexIb(resultindex2);
      }
     if(fightcharge1)
     {
       setFlightcharge(fightcharge1);
     }
     if(fightcharge2)
     {
       setFlightchargeIb(fightcharge2);
     }
   const allcust=localStorage.getItem('allss');
   if (allcust) {
       setPassenStr(allcust);
     }
    const data1=
    { 
       "ResultIndex": resultindex1,
       "TraceId": traceId1
      };
      const dataIb1=
    { 
       "ResultIndex": resultindex2,
       "TraceId": traceId1
      };
setData(data1);
setDataIb(dataIb1);
     },[]) ;
     useEffect(() => {
      function getData1() {
        const ob1=localStorage.getItem('flightchargeOb');
        const prob1=localStorage.getItem('price');
        const ib1=localStorage.getItem('flightchargeIb');
        const prib1=localStorage.getItem('priceIb');
        let baseob1=0;
        let totpriceob1=0;
        let baseib1=0;
        let totpriceib1=0;
        if(ob1)
        {baseob1=ob1;}
        if(ib1)
        {baseib1=ib1;}
        if(prob1)
        {totpriceob1=prob1;}
        if(prib1)
        {totpriceib1=prib1;}
        let totalbasefare=parseFloat(baseob1)+parseFloat(baseib1);
        let totalprice=parseFloat(totpriceob1)+parseFloat(totpriceib1);
        let serviceprice=parseFloat(totalprice)-parseFloat(totalbasefare);
      
       // setBasefare(totalbasefare);
       // setServicefare(serviceprice);
       
        //setTotalfare(totalprice);
  
        }
        getData1();
    },[]) ;
    useEffect(() => {
      const fetchBalance = async () => {
       try {
      
          const response = await axios.post('https://api.travelxpo.in/auth/b2c/get-balance');
         
          if(response.data.Error.ErrorCode==0)
                 {                
                  const agentBalance1=response.data.CashBalance;
                  setAgentBalance(agentBalance1);
                 }
          else{
            console.log(response.data.Error.ErrorMessage);
          }
          } catch (error) {
              if (error.isAxiosError) {
                  // Handle AxiosError here
                  console.error(error.response); // Error response
                  console.error(error.request); // Request that caused the error
                  console.error(error.message); // Error message
                } else {
                  console.error(error);     
                }
              }
          };
          fetchBalance();                
        },[]) ;
useEffect(() => {
              const gtotal=parseFloat(totalBags) + parseFloat(totalMeal) + parseFloat(totalfare)+parseFloat(totalBagsIb) + parseFloat(totalMealIb) + parseFloat(totalfareIb)  ; 
            if(agentBalance>0 && gtotal>0){
             
              setGrandTotal(gtotal); 
             
                 if(agentBalance<gtotal) 
                  {
                    setPrevent(0);
                    alert("Error code: 1001 \n Cann`t proceed...\nPlease Contact Support. ");
                  } 
                  else{
                    setPrevent(1); 
                  }
                }
            },[totalBags,totalMeal,totalfare,totalBagsIb,totalMealIb,totalfareIb,agentBalance]) ;
  useEffect(() => {
    if(value){
      const fetchData = async () => {
       try {
          const response = await axios.post('https://api.travelxpo.in/auth/b2c/fare-rule', data);
          //console.log("Data----"+JSON.stringify(response.data)) ;
          if(response.data.Response.Error.ErrorCode=="0")
                 { setFare(response.data);
                 }
          else{
            console.log(response.data.Response.Error.ErrorMessage);
          }
          } catch (error) {
              if (error.isAxiosError) {
                  // Handle AxiosError here
                  console.error(error.response); // Error response
                  console.error(error.request); // Request that caused the error
                  console.error(error.message); // Error message
                } else {
                  console.error(error);     
                }
              }
          };
          const fetchSSR = async () => {
            //alert("in fetchSSR"); 
         //   if(farequote){
            try {
               const responsessr = await axios.post('https://api.travelxpo.in/auth/b2c/ssr', data);
              console.log("Datassr---"+JSON.stringify(responsessr.data)) ;
               if(responsessr.data.Response.Error.ErrorCode=="0")
                      { 
                        const meal=responsessr.data.Response.MealDynamic[0];
      meal.forEach(item => {
        item.Price = parseFloat(Number(item.Price));
      });
      setSSRmeal(meal);
      console.log(JSON.stringify(meal));
      const SSR=responsessr.data.Response.Baggage[0];
      SSR.forEach(item => {
        item.Price = parseFloat(Number(item.Price));
      });
      //const selectedBaggage = SSR.filter((item, index) => selectedItems[index]);
      console.log(JSON.stringify(SSR));
                        setSSRbag(SSR);
                        setSSRseat(responsessr.data.Response.SeatDynamic.SegmentSeat.RowSeats);
                      }
               else{
                console.log(responsessr.data.Response.Error.ErrorMessage);
               }
               } catch (error) {
                if (error.isAxiosError) { 
                    // Handle AxiosError here
                    console.error(error.response); // Error response
                    console.error(error.request); // Request that caused the error
                    console.error(error.message); // Error message
                  } else {
                    console.error(error);         
                  }
                   }

                 // }


               };
          const fetchFareQuoteData = async () => {
              console.log("in fetchFareQuoteData");
              try {
                 const responseqt = await axios.post('https://api.travelxpo.in/auth/b2c/fare-quote', data);
                 //console.log("Dataquote---"+JSON.stringify(responseqt.data)) ;
                 if(responseqt.data.Response.Error.ErrorCode=="0")
                        { 
                          const basefr=responseqt.data.Response.Results.Fare.PublishedFare;
                          //alert(basefr);  
                          setBasefare(parseFloat(basefr)+parseFloat(markup));
                          setTotalfare(parseFloat(basefr)+parseFloat(markup)); 
                          
                          setFarequote(responseqt.data);
                          const reff=responseqt.data.Response.Results.IsRefundable;
                          const lcc=responseqt.data.Response.Results.IsLCC;
                          const resultFareType1=responseqt.data.Response.Results.ResultFareType;
                          setResultFareType(resultFareType1);
                          setIsLCC(lcc);
                          if(reff==false)
                          {
                            setRefumdable("Non Refundable");
                          }
                          const fareBreakdown=responseqt.data.Response.Results.FareBreakdown ;
                          fareBreakdown.forEach(item => {
                            if (item.PassengerCount > 1) {
                              item.BaseFare = item.BaseFare / item.PassengerCount;
                              item.Tax = item.Tax / item.PassengerCount;
                          
                              if (item.TaxBreakUp && item.TaxBreakUp !== null) {
                                item.TaxBreakUp = item.TaxBreakUp.map(taxItem => ({
                                  ...taxItem,
                                  value: taxItem.value / item.PassengerCount
                                }));
                              }
                          
                              item.PassengerCount = 1;
                            }
                            switch(item.PassengerType) {
                              case 1:
                                setAdultFare(item);
                                break;
                              case 2:
                                setChildFare(item);
                                break;
                              case 3:
                                setInfantFare(item);
                                break;
                              default:
                                break;
                            }
                          });
                          fetchSSR();
                        }
                 else{
                  console.log(responseqt.data.Response.Error.ErrorMessage);
                 }
                 } catch (error) {
                  if (error.isAxiosError) {
                      // Handle AxiosError here
                      console.error(error.response); // Error response
                      console.error(error.request); // Request that caused the error
                      console.error(error.message); // Error message
                    } else {
                      console.error(error);       
                    }
                     }
                 };
                
          fetchData();
  
        fetchFareQuoteData();
        
                    }
                     
      },[value,data]) ;

      useEffect(() => {
        if(value){
          const fetchDataIb = async () => {
           try {
              const responseIb = await axios.post('https://api.travelxpo.in/auth/b2c/fare-rule', dataIb);
              //console.log("Data----"+JSON.stringify(response.data)) ;
              if(responseIb.data.Response.Error.ErrorCode=="0")
                     { setFareIb(responseIb.data);
                     }
              else{
                console.log(responseIb.data.Response.Error.ErrorMessage);
              }
              } catch (error) {
                  if (error.isAxiosError) {
                      // Handle AxiosError here
                      console.error(error.response); // Error response
                      console.error(error.request); // Request that caused the error
                      console.error(error.message); // Error message
                    } else {
                      console.error(error);     
                    }
                  }
              };
              const fetchSSRIb = async () => {
                // alert("in fetchSSRIb"); 
                // if(farequote){
                 try {
                    const responsessrIb = await axios.post('https://api.travelxpo.in/auth/b2c/ssr', dataIb);
                    console.log("DatassrIb---"+JSON.stringify(responsessrIb.data)) ;
                    if(responsessrIb.data.Response.Error.ErrorCode=="0")
                           { 
                             const meal=responsessrIb.data.Response.MealDynamic[0];
                             meal.forEach(item => {
                               item.Price = parseFloat(Number(item.Price));
                             });
                             setSSRmealIb(meal);
                             console.log(JSON.stringify(meal));
                             const SSR=responsessrIb.data.Response.Baggage[0];
                             SSR.forEach(item => {
                               item.Price = parseFloat(Number(item.Price));
                             });
                             //const selectedBaggage = SSR.filter((item, index) => selectedItems[index]);
                            // alert(JSON.stringify(SSR));
                                               setSSRbagIb(SSR);
                                               setSSRseatIb(responsessrIb.data.Response.SeatDynamic.SegmentSeat.RowSeats);
                           }
                    else{
                     console.log(responsessrIb.data.Response.Error.ErrorMessage);
                    }
                    } catch (error) {
                     if (error.isAxiosError) { 
                         // Handle AxiosError here
                         console.error(error.response); // Error response
                         console.error(error.request); // Request that caused the error
                         console.error(error.message); // Error message
                       } else {
                         console.error(error);         
                       }
                        }
 
                     //  }
 
 
                    };  
              const fetchFareQuoteDataIb = async () => {
                  console.log("in fetchFareQuoteDataIb");
                  try {
                     const responseqtIb = await axios.post('https://api.travelxpo.in/auth/b2c/fare-quote', dataIb);
                     //alert("Dataquote---"+JSON.stringify(responseqtIb.data)) ;
                     if(responseqtIb.data.Response.Error.ErrorCode=="0")
                            { 
                              const basefIb=responseqtIb.data.Response.Results.Fare.PublishedFare;
                             
                              setFarequoteIb(responseqtIb.data);
                                          setBasefareIb(parseFloat(basefIb)+parseFloat(markup));
                                          setTotalfareIb(parseFloat(basefIb)+parseFloat(markup));
                              const reff=responseqtIb.data.Response.Results.IsRefundable;
                              const lcc=responseqtIb.data.Response.Results.IsLCC;
                              setIsLCCIb(lcc);
                              if(reff==false)
                              {
                                setRefumdable("Non Refundable");
                              }
                              const fareBreakdown=responseqtIb.data.Response.Results.FareBreakdown ;
                              fareBreakdown.forEach(item => {
                                if (item.PassengerCount > 1) {
                                  item.BaseFare = item.BaseFare / item.PassengerCount;
                                  item.Tax = item.Tax / item.PassengerCount;
                              
                                  if (item.TaxBreakUp && item.TaxBreakUp !== null) {
                                    item.TaxBreakUp = item.TaxBreakUp.map(taxItem => ({
                                      ...taxItem,
                                      value: taxItem.value / item.PassengerCount
                                    }));
                                  }
                              
                                  item.PassengerCount = 1;
                                }
                                switch(item.PassengerType) {
                                  case 1:
                                    setAdultFareIb(item);
                                    break;
                                  case 2:
                                    setChildFareIb(item);
                                    break;
                                  case 3:
                                    setInfantFareIb(item);
                                    break;
                                  default:
                                    break;
                                }
                              });
                              fetchSSRIb();
                            }
                     else{
                      console.log(responseqtIb.data.Response.Error.ErrorMessage);
                     }
                     } catch (error) {
                      if (error.isAxiosError) {
                          // Handle AxiosError here
                          console.error(error.response); // Error response
                          console.error(error.request); // Request that caused the error
                          console.error(error.message); // Error message
                        } else {
                          console.error(error);       
                        }
                         }
                     };   
                          
            fetchDataIb();
            fetchFareQuoteDataIb();
            
                        }
                         
          },[value,dataIb]) ;
          useEffect(() => {
            if (Array.isArray(ssrbag) && ssrbag.length > 0) {
            const initialSelected = ssrbag.findIndex(item => item.Price === 0.00);
            if (initialSelected !== -1) {
              const updatedSelection = Array(ssrbag.length).fill(false);
              updatedSelection[initialSelected] = true;
              const selectedBag = ssrbag[initialSelected];
              const price = parseFloat(selectedBag.Price);
              setSelectedItems(updatedSelection);
              setTotalBags(price);
    
            }
          }
          }, [ssrbag]);

          useEffect(() => {
            if (Array.isArray(ssrbagIb) && ssrbagIb.length > 0) {
            const initialSelected = ssrbagIb.findIndex(item => item.Price === 0.00);
            if (initialSelected !== -1) {
              const updatedSelection = Array(ssrbagIb.length).fill(false);
              updatedSelection[initialSelected] = true;
              const selectedBag = ssrbagIb[initialSelected];
              const price = parseFloat(selectedBag.Price);
              setSelectedItemsIb(updatedSelection);
              setTotalBagsIb(price);
    
            }
          }
          }, [ssrbagIb]);
    
          useEffect(() => {
            if (Array.isArray(ssrmeal) && ssrmeal.length > 0) {
            const initialSelected = ssrmeal.findIndex(item => item.Price === 0);
            if (initialSelected !== -1) {
              const updatedSelectionMeal =Array(ssrmeal.length).fill(false);// [...selectedMeals];
                updatedSelectionMeal[initialSelected] = true;//!updatedSelectionMeal[index];
                const selectedMeal = ssrmeal[initialSelected];
                const price = parseFloat(selectedMeal.Price);
    
              setSelectedMeals(updatedSelectionMeal);
              setTotalMeal(price);
            }
            }
          }, [ssrmeal]);
          useEffect(() => {
            if (Array.isArray(ssrmealIb) && ssrmealIb.length > 0) {
            const initialSelected = ssrmealIb.findIndex(item => item.Price === 0);
            if (initialSelected !== -1) {
              const updatedSelectionMeal =Array(ssrmealIb.length).fill(false);// [...selectedMeals];
                updatedSelectionMeal[initialSelected] = true;//!updatedSelectionMeal[index];
                const selectedMeal = ssrmealIb[initialSelected];
                const price = parseFloat(selectedMeal.Price);
    
              setSelectedMealsIb(updatedSelectionMeal);
              setTotalMealIb(price);
            }
            }
          }, [ssrmealIb]);
      const [showDiv, setShowDiv] = useState(false);

      const toggleDiv = () => {
        setShowDiv(!showDiv);
      };
      const validationSchema = yup.object({
          title:yup
            .string()
            .required('Required'),
  // customerfName: yup
          //   .string()
          //   .max(20, 'Must be 20 characters or less') 
          //   .required('Required'),
          // customerlName: yup
          //   .string()
          //   .max(20, 'Must be 20 characters or less')
          //   .required('Required'), 
          customerfName: yup
          .string()
          .max(20, 'Must be 20 characters or less') 
          .required('First Name is required')
          .test('different-from-lastname', 'First Name cannot be the same as Last Name', function(value) {
            return value !== this.parent.customerlName;
          }),
        customerlName: yup
          .string()
          .max(20, 'Must be 20 characters or less') 
          .required('Last Name is required')
          .test('no-spaces', 'Last Name cannot contain spaces', function(value) {
            return !/\s/.test(value);
          }),
          dob: yup
            .date('Invalid date of Birth')
            .required('Required')
            .min(minDate, `Date should be after ${minDate.toLocaleDateString()}`)
            .max(maxDate, `Date should not exceed ${maxDate.toLocaleDateString()}`),
          nationality: yup
            .string()
            .required('Required'),
          ppnumber: yup
            .string()
            .required('Required'),

         email: yup
            .string()
            .email('Invalid email')
            .required('Required'),

          ccode: yup
            .string()
            .required('Required'),
          mobile: yup
            .string()
            .min(10, 'Must be at least 10 characters')
            .required('Required'),
          country: yup
            .string()
            .required('Required'),
          city: yup
            .string()
            .required('Required'),
          address1: yup
            .string()
            .required('Required'),
          address2: yup
            .string()
            .required('Required'),
      
        });
      const initialValues = {
          title:'Mr',
          customerfName : '',
          customerlName : '',
          dob:'',
          nationality: 'IN',
          ppnumber:'',
          expirydate:'',
          email:'',
          ccode:'+91',
          mobile : '',
          country:'IN',
          city:'',
          address1:'',
          address2:'',
  
        };
        
        const formik = useFormik({
          initialValues,
          validationSchema,
          onSubmit: async (values) => {
            const selectedMealDynamic1=selectedMealDynamic;
            const selectedBagDynamic1=selectedBagDynamic;
            const selectedMealDynamic1Ib=selectedMealDynamicIb;
            const selectedBagDynamic1Ib=selectedBagDynamicIb;

           let addc=adult;
           let cdc=child;
           let idc=infant;
           let gent=1;
           setAddress1(values.address1);
           setAddress2(values.address2);
           setCity(values.city);
           setCountry(values.country);
           setCcode(values.ccode);
           setMobile(values.mobile);
           setNationality(values.nationality);
           setEmail(values.email);
           if(values.title=='Mr' || values.title=='Mstr')
           {gent=1;}
    else  {gent=2;}      
          // console.log(JSON.stringify(passengers));
           if(adult==0 && child==0 && infant==0)
           {
             setHeading("Submit the Passengers Information"); 
             setBtnhead("Submit");
             setIsDivVisible(true);
           }
           console.log("pass COUNT"+adult);
           console.log("pass COUNT"+child);
           console.log("pass COUNT"+infant);
           if(infant>0 && addc==0 && cdc==0)
              {
              
                 const dattaI={
                 "Title":title,
                 "FirstName":values.customerfName,
                 "LastName":values.customerlName,
                 "PaxType": 3,
                 "DateOfBirth":moment(values.dob).format('YYYY-MM-DD')+"T00:00:00",
                 "Gender": gent,
                 "PassportNo": values.ppnumber,
                 "PassportExpiry": moment(values.expirydate).format('YYYY-MM-DD')+"T00:00:00",
                 "AddressLine1": values.address1,
                 "AddressLine2": values.address2,
                  "City": values.city,
                  "CountryCode": values.country,
                  "CellCountryCode": values.ccode,
                  "ContactNo": values.mobile,
                  "Nationality": values.nationality,
                  "Email": values.email,
                  "IsLeadPax": true,
                  "FFAirlineCode": null,
                  "FFNumber": "",
                  "Baggage":selectedBagDynamic1,
                  "BaggageIb":selectedBagDynamic1Ib,
                  "MealDynamic":selectedMealDynamic1,
                  "MealDynamicIb":selectedMealDynamic1Ib,
                  "Fare":infantFare,
                  "FareIb":infantFareIb,
                  "GSTCompanyAddress": "",
                  "GSTCompanyContactNumber": "",
                  "GSTCompanyName": "",
                  "GSTNumber": "",
                  "GSTCompanyEmail": ""
                  };
                  
                 passeng.push(dattaI);
                 setSelectedMealDynamic([]);
                  setSelectedBagDynamic([]);
                  setSelectedMealDynamicIb([]);
                  setSelectedBagDynamicIb([]);
                // setPassengers(passeng);
                 idc=infant-1;
                 setInfant(idc);
                 if(idc==0)
                 {setHeading("Please Submit the form");
                 setBtnhead("Submit");
                 setIsDivVisible(true);
                  }
                  else
                  {setHeading("Infant  Passenger");
                  setDobtext("between 0-2 years");
                  setMaxDt(today);
                setMinDt(twoYearsAgo);
                setDCount(2);
                  }
              const updatedPassengers = [...passengers, dattaI];
              setPassengers(updatedPassengers);
              }
           if(child>0 && addc==0)
           {
              //const y=childss1-cdc;
              const dattaC={
              "Title":title,
              "FirstName":values.customerfName,
              "LastName":values.customerlName,
              "PaxType": 2,
              "DateOfBirth":moment(values.dob).format('YYYY-MM-DD')+"T00:00:00",
              "Gender": gent,
              "PassportNo": values.ppnumber,
              "PassportExpiry": moment(values.expirydate).format('YYYY-MM-DD')+"T00:00:00",
              "AddressLine1": values.address1,
              "AddressLine2": values.address2,
               "City": values.city,
               "CountryCode": values.country,
               "CellCountryCode": values.ccode,
               "ContactNo": values.mobile,
               "Nationality": values.nationality,
               "Email": values.email,
               "IsLeadPax": true,
               "FFAirlineCode": null,
               "FFNumber": "",
               "Baggage":selectedBagDynamic1,
               "BaggageIb":selectedBagDynamic1Ib,
               "MealDynamic":selectedMealDynamic1,
               "MealDynamicIb":selectedMealDynamic1Ib,
               "Fare":childFare,
               "FareIb":childFareIb,
               "GSTCompanyAddress": "",
               "GSTCompanyContactNumber": "",
               "GSTCompanyName": "",
               "GSTNumber": "",
               "GSTCompanyEmail": ""
          };
          
              passeng.push(dattaC);
                  setSelectedMealDynamic([]);
                  setSelectedBagDynamic([]);
                  setSelectedMealDynamicIb([]);
                  setSelectedBagDynamicIb([]);
             // setPassengers(passeng);
              cdc=child-1;
              setChild(cdc);
              if(cdc==0 && idc==0)
              {setHeading("Please Submit the form");
              setBtnhead("Submit");
              setIsDivVisible(true);
               }
              
              else if(cdc==0)
              {setHeading("Infant  Passenger");
              mxDate.setFullYear(currentDate.getFullYear() - 0);
                mnDate.setFullYear(currentDate.getFullYear() - 2);
                setMinDate(mnDate);
                setMaxDate(mxDate);
                setDobtext("between 0-2 years");
                setMaxDt(today);
                setMinDt(twoYearsAgo);
                setDCount(2);
              }
               else
               {setHeading("Child  Passenger");
                
              }
               const updatedPassengers = [...passengers, dattaC];
            setPassengers(updatedPassengers);
           }
              if(adult>0)
              {
          //      alert("in adult");
               //   const z=adultss1-addc;
                  
                      const dattaA={
                      "Title": title,
                      "FirstName":values.customerfName,
                      "LastName":values.customerlName,
                      "PaxType": 1,
                      "DateOfBirth":moment(values.dob).format('YYYY-MM-DD')+"T00:00:00",
                      "Gender": gent,
                      "PassportNo": values.ppnumber,
                      "PassportExpiry": moment(values.expirydate).format('YYYY-MM-DD')+"T00:00:00",
                      "AddressLine1": values.address1,
                      "AddressLine2": values.address2,
                          "City": values.city,
                          "CountryCode": values.country,
                          "CellCountryCode": values.ccode,
                          "ContactNo": values.mobile,
                          "Nationality": values.nationality,
                          "Email": values.email,
                          "IsLeadPax": true,
                          "FFAirlineCode": null,
                          "FFNumber": "",
                          "Baggage":selectedBagDynamic1,
                          "BaggageIb":selectedBagDynamic1Ib,
                          "MealDynamic":selectedMealDynamic1,
                          "MealDynamicIb":selectedMealDynamic1Ib,
                          "DocumentDetails":documentDetails,
                          "Fare":adultFare,
                          "FareIb":adultFareIb,
                          "GSTCompanyAddress": "",
                          "GSTCompanyContactNumber": "",
                          "GSTCompanyName": "",
                          "GSTNumber": "",
                          "GSTCompanyEmail": ""
                      };
                      
                 passeng.push(dattaA);
                  setSelectedMealDynamic([]);
                  setSelectedBagDynamic([]);
                  setSelectedMealDynamicIb([]);
                  setSelectedBagDynamicIb([]);
              
                 addc=adult-1;
                 setAdult(addc);
                 if(addc+cdc+idc!=0)
                {
                 formik.setFieldValue('customerfName', ''); 
                 formik.setFieldValue('customerlName', '');
                 formik.setFieldValue('dob', '');
                 formik.setFieldValue('ppnumber', ''); 
                 formik.setFieldValue('expirydate', '');   
                }
                 if(addc==0 && cdc==0 && idc==0)
              {setHeading("Please Submit the form");
              setBtnhead("Submit");
              setIsDivVisible(true);
               }
                else  if(addc==0 && cdc>0)
                {setHeading("Child  Passenger");
                mxDate.setFullYear(currentDate.getFullYear() - 2);
                mnDate.setFullYear(currentDate.getFullYear() - 12);
                setMinDate(mnDate);
                setMaxDate(mxDate);
                setDobtext("between 2-12 years");
                setMaxDt(twoYearsAgo);
              setMinDt(twelveYearsAgo);
              setDCount(10);
                  }
                  else if(addc==0 && cdc==0 && idc>0)
              {setHeading("Infant  Passenger");
              mxDate.setFullYear(currentDate.getFullYear() - 0);
                mnDate.setFullYear(currentDate.getFullYear() - 2);
                setMinDate(mnDate);
                setMaxDate(mxDate);
                setDobtext("between 0-2 years");
                setMaxDt(today);
                setMinDt(twoYearsAgo);
                setDCount(2);
              }
                 else
                 {setHeading("adult  Passenger");
                 
                 }
                 const updatedPassengers = [...passengers, dattaA];
                  setPassengers(updatedPassengers);
              }
              if(addc>0 || cdc>0 || idc>0)
              {
              formik.setFieldValue('customerfName', '');
              formik.setFieldValue('customerlName', '');
              formik.setFieldValue('dob', '');
              formik.setFieldValue('ppnumber', '');
              formik.setFieldValue('expirydate', '');
              }
              const markupp=parseFloat(priceOb)-parseFloat(flightcharge); 
              const priceObLast=parseFloat(priceOb)+parseFloat(totalMeal)+parseFloat(totalBags);
              const flightChargeLast=parseFloat(priceObLast)-parseFloat(markupp);

              const markuppib=parseFloat(priceIb)-parseFloat(flightchargeIb); 
              const priceIbLast=parseFloat(priceIb)+(totalMealIb)+parseFloat(totalBagsIb);
              const flightChargeLastIb=parseFloat(priceIbLast)-parseFloat(markuppib);

              localStorage.setItem('price', priceObLast);
              localStorage.setItem('flightchargeOb', flightChargeLast);
              localStorage.setItem('priceIb', priceIbLast);
              localStorage.setItem('flightchargeIb', flightChargeLastIb);
              localStorage.setItem('grandtotal', grandTotal);

            if(btnhead=="Submit")
            {
              if(value){
                if(prevent){

                localStorage.setItem('markupamount', markupp);
                localStorage.setItem('markupamountib', markuppib);
             const jtype= localStorage.getItem('journeyType');
             const passtype="b2c";
             localStorage.removeItem('passengerOB'); 
             localStorage.removeItem('passengerIB'); 
             const updatedPassengersob = passengers.map(passenger => {
              // Destructure the passenger object and omit the specified properties
              const { FareIb, MealDynamicIb, BaggageIb, ...rest } = passenger;
              return rest;
          });
          
          const updatedPassengersib = passengers.map(passenger => {
            // Remove specified properties
            const { Fare, MealDynamic, Baggage, ...rest } = passenger;
            return rest;
            });
            const finalUpdatedPassengersib = updatedPassengersib.map(updatedPassengerr => ({
              ...updatedPassengerr,
              Fare: updatedPassengerr.FareIb,
              Baggage: updatedPassengerr.BaggageIb,
              MealDynamic: updatedPassengerr.MealDynamicIb,
            }));
            const updatedPassengersibib = finalUpdatedPassengersib.map(finalUpdatedPassenger => {
              // Destructure the passenger object and omit the specified properties
              const { FareIb, MealDynamicIb, BaggageIb, ...rest } = finalUpdatedPassenger;
              return rest;
            });
                          const data=
                          {
                            
                              "ResultIndex": resultindex,
                              "agentId":parseInt(1),
                              "branchId":parseInt(1),
                              "markup":markupp,
                              "journeyType":jtype,
                              "userId":0,
                              "passengerType":passtype,
                              "totalBookingFare":priceObLast,
                              "flightcharge":parseFloat(flightChargeLast),
                              "adultCount":parseInt(adultss1),
                              "childCount":parseInt(childss1),
                              "infantCount":parseInt(infants1), 
                              "Passengers": updatedPassengersob,
                              "IsLCC":isLCC,
                              "TraceId": traceId
                          };
                          const dataobib=
                          {
                            
                              "ResultIndex": resultindexIb,
                              "agentId":parseInt(1),
                              "branchId":parseInt(1),
                              "markup":markuppib,
                              "journeyType":jtype,
                              "userId":0,
                              "passengerType":passtype,
                              "totalBookingFare":priceIbLast,
                              "flightcharge":parseFloat(flightChargeLastIb),
                              "adultCount":parseInt(adultss1),
                              "childCount":parseInt(childss1),
                              "infantCount":parseInt(infants1), 
                              "Passengers": updatedPassengersibib,
                              "IsLCC":isLCCIb,
                              "TraceId": traceId
                          };
 
           localStorage.setItem('passengerOB',JSON.stringify(data));
           localStorage.setItem('passengerIB',JSON.stringify(dataobib));
          navigate('/PaymentCCAvenue'); 
          // navigate('/paymentSuccess');
                                      }
                                      else{
                                        alert("Error code: 1001 \n Cann`t proceed...\nPlease Contact Support. ");
                                      }


                        }
                        else
                        {
                          alert("Please SignIn/SignUp");
                        }
              }
  
              
  
          },
        });
        const [title, setTitle] = useState('Mr');
        const [customerfName, setCustomerfName] = useState('');
        const [customerlName, setCustomerlName] = useState('');
        const [dob, setDob] = useState('');
        const [nationality, setNationality] = useState('IN');
        const [ppnumber, setPpnumber] =useState('');
        const [expirydate, setExpirydate] = useState('');
        const [showModal, setShowModal] = useState(false);
        const [selectedPassenger, setSelectedPassenger] = useState(null);
        const [selectedIndex, setSelectedIndex] = useState(0);    
  
    const handleChange = (event) => {
     // alert(event.target.value);
      setTitle(event.target.value);
    };
    function formatDateString(dateString) {
      const dateParts = dateString.split('T')[0].split('-');
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    }
    const [showBaggage, setShowBaggage] = useState(false);
    const [showMeals, setShowMeals] = useState(false);
    const [showBaggageIb, setShowBaggageIb] = useState(false);
    const [showMealsIb, setShowMealsIb] = useState(false);

    const handleBaggageClick = () => {

      setShowBaggage(prevShowBaggage => !prevShowBaggage);
      setShowBaggageIb(false);
      setShowMeals(false);
      setShowMealsIb(false);
     
    };
    const handleBaggageClickIb = () => {

      setShowBaggageIb(prevShowBaggageIb => !prevShowBaggageIb);
      setShowBaggage(false);
      setShowMeals(false);
      setShowMealsIb(false);
      
    };
    const handleMealsClick = () => {
      setShowMeals(prevShowMeals => !prevShowMeals); 
      setShowMealsIb(false);
      setShowBaggage(false);
      setShowBaggageIb(false);
      
       
    };
    const handleMealsClickIb = () => {
      setShowMealsIb(prevShowMealsIb => !prevShowMealsIb); 
      setShowMeals(false);
      setShowBaggage(false);
      setShowBaggageIb(false);
     
       
    };
    const openModalR = (passenger,index) => { 
      setSelectedIndex(index);
      setSelectedPassenger(passenger);
      setEditedTitle(passenger.Title);
      setEditedFirstName(passenger.FirstName);
      setEditedLastName(passenger.LastName);
      setEditedDateOfBirth(passenger.DateOfBirth.replace(/T00:00:00$/, ""));
      setEditedPPNumber(passenger.PassportNo);
      setEditedPPExpireDate(passenger.PassportExpiry.replace(/T00:00:00$/, ""));
      setEditedPaxType(passenger.PaxType);
      setEditedMealDynamic(passenger.MealDynamic);
      setEditedBagDynamic(passenger.Baggage);
      setEditedFare(passenger.Fare);
      setEditedMealDynamicIb(passenger.MealDynamicIb);
      setEditedBagDynamicIb(passenger.BaggageIb);
      setEditedFareIb(passenger.FareIb);
      if(passenger.PaxType==1)
      {
        setEditedPassType(" Adult Passenger Age Above 12 Years");
        setMinDt(hundredBack);
          setMaxDt(twelveYearsAgo);
          setDCount(100);
      }
      else if(passenger.PaxType==2)
      {
        setEditedPassType(" Child  Passenger Age Between 2 and 12 Years");
        setMinDt(twelveYearsAgo);
          setMaxDt(twoYearsAgo);
          setDCount(10);
      }
      else{
        setEditedPassType(" Infant  Passenger Age below 2 Years");
        setMinDt(twoYearsAgo);
          setMaxDt(today);
          setDCount(2);
      }
      setShowModal(true);
    };
    const closeModal = () => {
      setShowModal(false);
      setSelectedPassenger(null);
    };
  
    const saveEditedPassenger = (editedPassenger) => {
      // Update the passenger in your data
      const updatedPassengers = passengers.map((passenger) =>
        passenger === selectedPassenger ? editedPassenger : passenger
      );

      setPassengers(updatedPassengers);
      closeModal();
    };

const handleSave = () => {
  let g=1;
  let gent=1;
  if(editedTitle=='Mr'|| editedTitle=='Mstr')
      {gent=1;}
       else  {gent=2;}  
  const updatedPassenger = {
    Title:editedTitle,
    FirstName: editedFirstName,
    LastName: editedLastName,
    PaxType: editedPaxType,
    DateOfBirth: editedDateOfBirth+"T00:00:00",
    PassportNo: editedPPNumber,
    PassportExpiry: editedPPExpireDate+"T00:00:00",
    Gender:gent,
    AddressLine1: address1,
    AddressLine2 : address2,
    City: city,
    CountryCode:country,
    CellCountryCode:ccode,
    ContactNo:mobile,
    Nationality:"IN",
    Email:email,
    IsLeadPax:true,
    FFAirlineCode:null,
    FFNumber:"",
    MealDynamic:editedMealDynamic,
    MealDynamicIb:editedMealDynamicIb,
    Baggage:editedBagDynamic,
    BaggageIb:editedBagDynamicIb,
    Fare:editedFare,
    FareIb:editedFareIb,
    GSTCompanyAddress:"",
    GSTCompanyContactNumber:"",
    GSTCompanyName:"",
    GSTNumber:"",
    GSTCompanyEmail:"",

  };
  onSave(updatedPassenger);
  onClose();
};
const onSave = (updatedPassenger) => {
 // console.log('Saving updated passenger:', updatedPassenger);
  //setPassengers(updatedPassenger);

  const indexToUpdate = selectedIndex;
  const updatedPassengers = [...passengers];
 
  updatedPassengers[indexToUpdate] = updatedPassenger;

  setPassengers(updatedPassengers);
};
const onClose = () => {
  setShowModal(false);
};
const [selectedItems, setSelectedItems] = useState([]);
const [selectedMeals, setSelectedMeals] = useState([]);
const [selectedItemsIb, setSelectedItemsIb] = useState([]);
const [selectedMealsIb, setSelectedMealsIb] = useState([]);
// Function to handle item selection

const handleItemSelect = (index) => {
  const updatedSelection = Array(ssrbag.length).fill(false);
  updatedSelection[index] = true;
  const selectedBag = ssrbag[index];
  const price = parseFloat(selectedBag.Price);
  setSelectedItems(updatedSelection);
  setTotalBags(price);  
};
const handleItemSelectIb = (indexIb) => {
  const updatedSelectionIb = Array(ssrbagIb.length).fill(false);
  updatedSelectionIb[indexIb] = true;
  const selectedBagIb = ssrbagIb[indexIb];
  const price = parseFloat(selectedBagIb.Price);
  setSelectedItemsIb(updatedSelectionIb);
  setTotalBagsIb(price);  
};
const submitSelectedItems = () => {
  const selectedBagDynamic = ssrbag.filter((item, index) => selectedItems[index]);
  const selectedBaggageWithFloatPrice = selectedBagDynamic.map(item => ({
    ...item,
    Price: parseFloat(Number(item.Price)) // Convert the price to float using parseFloat()
}));

setSelectedBagDynamic(selectedBaggageWithFloatPrice);
//alert(JSON.stringify(selectedBagDynamic));
};
const submitSelectedItemsIb = () => {
  const selectedBagDynamicIb = ssrbagIb.filter((item, indexIb) => selectedItemsIb[indexIb]);
  const selectedBaggageWithFloatPriceIb = selectedBagDynamicIb.map(item => ({
    ...item,
    Price: parseFloat(Number(item.Price)) // Convert the price to float using parseFloat()
}));
setSelectedBagDynamicIb(selectedBaggageWithFloatPriceIb);
//alert(JSON.stringify(selectedBagDynamic));
};
const handleMealSelect = (index) => {
  const updatedSelectionMeal =Array(ssrmeal.length).fill(false);// [...selectedMeals];
  updatedSelectionMeal[index] = true;//!updatedSelectionMeal[index]; 
  const selectedMeal = ssrmeal[index];
  const price = parseFloat(selectedMeal.Price);
  /*if (!updatedSelectionMeal[index]) {
    setTotalMeal(price);} 
    else {setTotalMeal( price);}*/
  setSelectedMeals(updatedSelectionMeal);
  setTotalMeal(price);
};
const handleMealSelectIb = (indexIb) => {
  const updatedSelectionMealIb =Array(ssrmealIb.length).fill(false);// [...selectedMeals];
  updatedSelectionMealIb[indexIb] = true;//!updatedSelectionMeal[index]; 
  const selectedMealIb = ssrmealIb[indexIb];
  const price = parseFloat(selectedMealIb.Price);
  /*if (!updatedSelectionMeal[index]) {
    setTotalMeal(price);} 
    else {setTotalMeal( price);}*/
  setSelectedMealsIb(updatedSelectionMealIb);
  setTotalMealIb(price);
};
const handleChangeNumber = (index, quantity) => {
  const selectedMeal = ssrmeal[index];
  const price = parseFloat(Number(selectedMeal.Price * quantity));
  let updatedTotalMeal = totalMeal;
  if (selectedMeals[index]) {
    updatedTotalMeal = parseFloat(Number(totalMeal - (selectedMeal.Quantity * parseFloat(selectedMeal.Price)) + price));
    }
  selectedMeal.Quantity=quantity;
  setTotalMeal(updatedTotalMeal);
  selectedMeal.Quantity = quantity;
  const updatedSelectionMeal = [...selectedMeals];
  setSelectedMeals(updatedSelectionMeal);
  const dynamicSelection = ssrmeal.filter((item, index) => updatedSelectionMeal[index]);
  setSelectedMealDynamic(dynamicSelection);
};
const handleChangeNumberIb = (indexIb, quantity) => {
  const selectedMealIb = ssrmealIb[indexIb];
  const price = parseFloat(Number(selectedMealIb.Price * quantity));
  let updatedTotalMealIb = totalMealIb;
  if (selectedMealsIb[indexIb]) {
    updatedTotalMealIb = parseFloat(Number(totalMealIb - (selectedMealIb.Quantity * parseFloat(selectedMealIb.Price)) + price));
    }
  selectedMealIb.Quantity=quantity;
  setTotalMealIb(updatedTotalMealIb);
  selectedMealIb.Quantity = quantity;
  const updatedSelectionMealIb = [...selectedMealsIb];
  setSelectedMealsIb(updatedSelectionMealIb);
  const dynamicSelectionIb = ssrmealIb.filter((item, indexIb) => updatedSelectionMealIb[indexIb]);
  setSelectedMealDynamicIb(dynamicSelectionIb);
};


const submitSelectedMeals= () => {
  const selectedMealDynamic = ssrmeal.filter((item, index) => selectedMeals[index]);
  const selectedMealDynamicWithFloatPrice = selectedMealDynamic.map(item => ({
    ...item,
    Price: parseFloat(Number(item.Price)) // Convert the price to float using parseFloat()
}));
  setSelectedMealDynamic(selectedMealDynamicWithFloatPrice);
 //alert(JSON.stringify(selectedMealDynamic));
};
const submitSelectedMealsIb= () => {
  const selectedMealDynamicIb = ssrmealIb.filter((item, indexIb) => selectedMealsIb[indexIb]);
  const selectedMealDynamicWithFloatPriceIb = selectedMealDynamicIb.map(item => ({
    ...item,
    Price: parseFloat(Number(item.Price)) // Convert the price to float using parseFloat()
}));
  setSelectedMealDynamicIb(selectedMealDynamicWithFloatPriceIb);
 //alert(JSON.stringify(selectedMealDynamic));
};
const [selectedMealIndex, setSelectedMealIndex] = useState(null);
const [selectedMealIndexIb, setSelectedMealIndexIb] = useState(null);
const handleMealSelectEdit = (index) => {
  const selectedMeal = ssrmeal[index];
  
  // Replace the first meal with the selected meal
  const updatedMeals = [...editedMealDynamic];
  updatedMeals[0] = selectedMeal;
  
  setEditedMealDynamic(updatedMeals);
};
const handleMealSelectEditIb = (index) => {
  const selectedMealIb = ssrmealIb[index];
  
  // Replace the first meal with the selected meal
  const updatedMealsIb = [...editedMealDynamicIb];
  updatedMealsIb[0] = selectedMealIb;
  
  setEditedMealDynamicIb(updatedMealsIb);
};
const handleMealCancel = () => {
  setEditedMealDynamic([]);
};
const handleMealCancelIb = () => {
  setEditedMealDynamicIb([]);
};
const [selectedBagIndex, setSelectedBagIndex] = useState(null);
const [selectedBagIndexIb, setSelectedBagIndexIb] = useState(null);
const handleItemSelectEdit = (index) => {
  const selectedBag = ssrbag[index];
  
  // Replace the first meal with the selected meal
  const updatedBags = [...editedBagDynamic];
  updatedBags[0] = selectedBag;
  
  setEditedBagDynamic(updatedBags);

};
const handleItemSelectEditIb = (index) => {
  const selectedBagIb = ssrbagIb[index];
  
  // Replace the first meal with the selected meal
  const updatedBagsIb = [...editedBagDynamicIb];
  updatedBagsIb[0] = selectedBagIb;
  
  setEditedBagDynamicIb(updatedBagsIb);

};
const handleBagCancel = () => {
  setEditedBagDynamic([]);
};
const handleBagCancelIb = () => {
  setEditedBagDynamicIb([]);
};
 useEffect(() => {
   // Find the index of the previously selected meal
   const selectedIndex =1;//ssrmeal.findIndex(item => item.Code === editedMealDynamic.Code);
   if (selectedIndex !== -1) {
     setSelectedMealIndex(selectedIndex);
   } else {
     setSelectedMealIndex(null); // If the previously selected meal is not found, reset the selected meal
   }
 }, [editedMealDynamic, ssrmeal]);
 useEffect(() => {
   // Find the index of the previously selected meal
   const selectedIndex =1;// ssrbag.findIndex(item => item.Code === editedBagDynamic.Code);
   if (selectedIndex !== -1) {
     setSelectedBagIndex(selectedIndex);
   } else {
     setSelectedBagIndex(null); // If the previously selected meal is not found, reset the selected meal
   }
 }, [editedBagDynamic, ssrbag]);
 useEffect(() => {
  // Find the index of the previously selected meal
  const selectedIndexIb =1;// ssrmealIb.findIndex(item => item.Code === editedMealDynamicIb.Code);
  if (selectedIndexIb !== -1) {
    setSelectedMealIndexIb(selectedIndexIb);
  } else {
    setSelectedMealIndexIb(null); // If the previously selected meal is not found, reset the selected meal
  }
}, [editedMealDynamicIb, ssrmealIb]);
useEffect(() => {
  // Find the index of the previously selected meal
  const selectedIndexIb =1;// ssrbagIb.findIndex(item => item.Code === editedBagDynamicIb.Code);
  if (selectedIndexIb !== -1) {
    setSelectedBagIndexIb(selectedIndexIb);
  } else {
    setSelectedBagIndexIb(null); // If the previously selected meal is not found, reset the selected meal
  }
}, [editedBagDynamicIb, ssrbagIb]);
useEffect(() => {
  let totalMealPrice = 0;
  let totalBagPrice = 0;
  let totalMealPriceIb = 0;
  let totalBagPriceIb = 0;
  passengers.forEach(passenger => {
    // Check if the passenger has MealDynamic
    if (passenger.MealDynamic && passenger.MealDynamic.length > 0) {
      // Iterate through MealDynamic of the passenger and sum up the prices
      passenger.MealDynamic.forEach(meal => {
        totalMealPrice += meal.Price;
      });
    }
    if (passenger.MealDynamicIb && passenger.MealDynamicIb.length > 0) {
      // Iterate through MealDynamic of the passenger and sum up the prices
      passenger.MealDynamicIb.forEach(meal => {
        totalMealPriceIb += meal.Price;
      });
    }
    if (passenger.Baggage && passenger.Baggage.length > 0) {
      // Iterate through MealDynamic of the passenger and sum up the prices
      passenger.Baggage.forEach(bag => {
        totalBagPrice += bag.Price;
      });
    }
    if (passenger.BaggageIb && passenger.BaggageIb.length > 0) {
      // Iterate through MealDynamic of the passenger and sum up the prices
      passenger.BaggageIb.forEach(bag => {
        totalBagPriceIb += bag.Price;
      });
    }
  });
  setTotalMeal(totalMealPrice);
  setTotalMealIb(totalMealPriceIb);
  setTotalBags(totalBagPrice);
  setTotalBagsIb(totalBagPriceIb);
 // setFlightcharge(flightcharge+totalMealPrice+totalBagPrice);
}, [passengers]);

const [documentId, setDocumentId] = useState("");
const handleDocumentIdChange = (e) => {
  setDocumentId(e.target.value);
  const docid=e.target.value;
  let typeid='';
  if(resultFareType==3)
  {typeid='StudentId';}
  else if(resultFareType==4)
    {typeid='MilitaryId';}
  else if(resultFareType==5)
    {typeid='SeniorCitizenId';}
  setDocumentDetails([
    {
      "DocumentTypeId": typeid,
      "DocumentNumber": docid
    }
  ]);

};
const renderDocumentIdInput = () => {
  if (resultFareType !== "RegularFare") {
    return (
      <div>
        <label htmlFor="documentId">{resultFareType} Document ID:</label>
        <input
          type="text"
          id="documentId"
          value={documentId}
          onChange={handleDocumentIdChange}
          required
        />
      </div>
    );
  }
  return null;
};
  return (
    <div>
        <TopbarSearch />
        <section class="bg-white"> 
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                      <div className="col-lg-12 detailsview">
                        <div className="card">
                            <div className="card-body">
                              <h4>TICKET DETAILS</h4>
                            </div>
                              <div className="cardsection">
                                <div className="row">
                                  <div className="col-lg-5 col-4">
                                    <p></p>
                                  </div>
                                  <div className="col-lg-4 col-6 text-end linkssec">
                                    <p>Baggage and fare rules</p>
                                  </div>
                                  <div className="col-lg-3 text-end">
                                
                            
                                    <button className="btn btn-dark mb-2" > {refundable}</button>
                                    
                                  </div>
                              </div>
                              <div className="card">
                                <div className="card-body p-0">
                                  <div className="table-responsive mt-4">
                                    <table className="table dt-responsive tableflights bg-white table-borderless">
                                      <tbody>
                                      {farequote && farequote.Response.Results.Segments[0].map((seggm, index) => {
                                        return(

                                        <tr  key={index}>
                                          <th style={{width:"15%",paddingLeft: "1rem"}}>
                                          {/* <p>{seggm?.Destination?.Airport?.AirportCode}{seggm?.FlightStatus}{seggm?.StopPointArrivalTime}cc</p> */}
                                            {/* <i className="fa fa-square onemenu" aria-hidden="true"></i>  */}
                                            <img className="flight-info-airline__logo airline-logo" src={`images/AirlineLogo_25x25/${seggm?.Airline?.AirlineCode}.gif`} alt="" />
                                           
                                            <div className="filghtsdetails">
                                             
                                              <p>{seggm?.Airline?.AirlineName}<br />  {seggm?.Airline?.AirlineCode}- {seggm?.Airline?.FlightNumber}</p>
                                            </div>
                                          </th>
                                          <th style={{width: "25%"}}> 
                                            <div className="filghtsdetails">
                                            <h3 style={{fontSize:"15px"}}>Origin</h3>	
                                            <h4>{new Date(seggm?.Origin?.DepTime).toLocaleTimeString([], { year: 'numeric', month: 'long', day: 'numeric' })}</h4>	
                                               <h2 style={{fontSize:"15px"}}>{seggm?.Origin?.Airport?.AirportCode}</h2>
                                              <p>{seggm?.Origin?.Airport?.AirportName}<br /> 
                                              {seggm?.Origin?.Airport?.CityName},{seggm?.Origin?.Airport?.CountryName}</p>
                                            </div>
                                          </th>
                                          
                                          <th style={{width: "35%"}}> 
                                            <div className="filghtsdetails mt-3">	
                                              <h2  style={{fontSize:"15px"}}>{Math.floor(seggm?.Duration / 60)} h {Math.floor(seggm?.Duration % 60)} m</h2>
                                              <p>Flight Duration</p> 
                                              <br />
                                              <h2  style={{fontSize:"15px"}}>Baggage Info:</h2>
                                            
                                              <p>
                                              Check-in-{seggm?.Baggage} (1 piece only) , <br /> Cabin Baggage-{seggm?.CabinBaggage} - (1 piece only) 
                                                </p>
                                            </div>
                                          </th>
                                          <th style={{width: "25%"}}> 
                                            <div className="filghtsdetails">
                                            <h3 style={{fontSize:"15px"}}>Destination</h3>	
                                            <h4>{new Date(seggm?.Destination?.ArrTime).toLocaleTimeString([], { year: 'numeric', month: 'long', day: 'numeric' })}</h4>	
                                           
                                              <h2 style={{fontSize:"15px"}}>{seggm?.Destination?.Airport?.AirportCode}</h2>
                                              <p>{seggm?.Destination?.Airport?.AirportName}<br /> 
                                              {seggm?.Destination?.Airport?.CityName},{seggm?.Origin?.Airport?.CountryName}</p>
                                             
                                            </div>
         
                                          </th>

                                        </tr>
                                        
                                      )})}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div className="card">
                                <div className="card-body p-0">
                                  <div className="table-responsive mt-4">
                                    <table className="table dt-responsive tableflights bg-white table-borderless">
                                      <tbody>
                                      {farequoteIb && farequoteIb.Response.Results.Segments[0].map((seggm, index) => {
                                        return(

                                        <tr  key={index}>
                                          <th style={{width:"15%",paddingLeft: "1rem"}}>
                                          {/* <p>{seggm?.Destination?.Airport?.AirportCode}{seggm?.FlightStatus}{seggm?.StopPointArrivalTime}cc</p> */}
                                            {/* <i className="fa fa-square onemenu" aria-hidden="true"></i>  */}
                                            <img className="flight-info-airline__logo airline-logo" src={`images/AirlineLogo_25x25/${seggm?.Airline?.AirlineCode}.gif`} alt="" />
                                           
                                            <div className="filghtsdetails">
                                             
                                              <p>{seggm?.Airline?.AirlineName}<br />  {seggm?.Airline?.AirlineCode}- {seggm?.Airline?.FlightNumber}</p>
                                            </div>
                                          </th>
                                          <th style={{width: "25%"}}> 
                                            <div className="filghtsdetails">
                                            <h3 style={{fontSize:"15px"}}>Origin</h3>	
                                            <h4>{new Date(seggm?.Origin?.DepTime).toLocaleTimeString([], { year: 'numeric', month: 'long', day: 'numeric' })}</h4>	
                                               <h2 style={{fontSize:"15px"}}>{seggm?.Origin?.Airport?.AirportCode}</h2>
                                              <p>{seggm?.Origin?.Airport?.AirportName}<br /> 
                                              {seggm?.Origin?.Airport?.CityName},{seggm?.Origin?.Airport?.CountryName}</p>
                                            </div>
                                          </th>
                                          
                                          <th style={{width: "35%"}}> 
                                            <div className="filghtsdetails mt-3">	
                                              <h2  style={{fontSize:"15px"}}>{Math.floor(seggm?.Duration / 60)} h {Math.floor(seggm?.Duration % 60)} m</h2>
                                              <p>Flight Duration</p> 
                                              <br />
                                              <h2  style={{fontSize:"15px"}}>Baggage Info:</h2>
                                            
                                              <p>
                                              Check-in-{seggm?.Baggage} (1 piece only) , <br /> Cabin Baggage-{seggm?.CabinBaggage} - (1 piece only) 
                                                </p>
                                            </div>
                                          </th>
                                          <th style={{width: "25%"}}> 
                                            <div className="filghtsdetails">
                                            <h3 style={{fontSize:"15px"}}>Destination</h3>	
                                            <h4>{new Date(seggm?.Destination?.ArrTime).toLocaleTimeString([], { year: 'numeric', month: 'long', day: 'numeric' })}</h4>	
                                           
                                              <h2 style={{fontSize:"15px"}}>{seggm?.Destination?.Airport?.AirportCode}</h2>
                                              <p>{seggm?.Destination?.Airport?.AirportName}<br /> 
                                              {seggm?.Destination?.Airport?.CityName},{seggm?.Origin?.Airport?.CountryName}</p>
                                             
                                            </div>
         
                                          </th>

                                        </tr>
                                        
                                      )})}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>           



                            </div>
                          </div>
                      </div>
                <div className="col-lg-12 detailsview mt-3">
                  <div className="card">
                    <div className="card-body ">
                      <h4>IMPORTANT IMFORMATION</h4>
                    </div>
                    <div className="cardsection">
                      <div className="row">
                      <h5>Fare Rules</h5>
                        <div className="col-lg-12 nbtext"   style={{ maxHeight: '300px', overflowY: 'auto' }}>
                       
                          {fare && fare.Response.FareRules.map((resu, index) => (
                          <p key={index}>{parseHTMLString(resu?.FareRuleDetail)}</p>
                          ))} 
                          <h5>Fare Rules Retrun</h5>
                          {fareIb && fareIb.Response.FareRules.map((resu, index) => (
                          <p key={index}>{parseHTMLString(resu?.FareRuleDetail)}</p>
                          ))}
                          {/* <h5>COVID Guidelines and Vaccination Requirements</h5>
                          <p>COVID test/vaccination rules: All travellers entering Kerala must either carry a final COVID vaccination certificate (15 days must have passed since final dose) OR a negative RT-PCR report with sample taken within 72 hours before arrival. 72 hour RT-PCR report is mandatory for travellers visiting for a short duration for purposes like business, official, trade, medical, court cases, property management etc. RT-PCR test timeline starts from the swab collection time. Travellers might not be allowed to board their flights if they are not carrying a valid test report/vaccination certificate. Thermal screening will be done for all travellers. Some travellers might have to undergo a self-paid RAT test upon arrival at the airport</p>
                          
                          <p>Quarantine rules:ravellers undergoing test on arrival shall remain under home/institutional quarantine till the test result is declared.</p>
                          <p>Pre-registration or e-pass rules: The transportation from airport to facility/home quarantine shall be in double chambered vehicles.</p>
                          
                          <p>Check the detailed list of travel guidelines issued by Indian States and UTs here KNOW MORE</p>
                          <p>If you have arrived on any international flight and are taking a connecting domestic flight, please check the 'Travelling to India' tab HERE.</p>
                          
                          <h5 className="mt-4 mb-4">Pre-Flight Checklist</h5>
                          <p>Remember to web check-in before arriving at the airport; carry a printed or soft copy of the boarding pass.</p>
                          <p>Wearing masks/face covers is no longer mandatory. However, all travellers are advised to wear them, in view of the threat posed by COVID-19.</p>
                          <p>One hand bag up to 7 kgs and 55x35x25cm, is allowed per traveller as cabin baggage. Certain personal articles like a lady's purse, laptop bags, etc. can be carried additionally.</p>
                          
                          <p className="mt-3"><b>DISCLAIMER:</b>The information provided above is only for ready reference and convenience of customers, and may not be exhaustive. We strongly recommend that you check the full text of the guidelines issued by the State Governments and Airlines before travelling to ensure smooth travel. We accept no liability in this regard. In case you do not meet the required guidelines, the airline or state authorities can stop you from travelling.</p> */}

                        </div>
                      </div>
                    </div>
                    <hr />
                    <p className="me-3">Was the information helpful?  Yes / No</p>
                  </div>
                </div>
             
                
                <div className="col-lg-12 thirdview detailsview">
                  <div className="card">
                    <div className="card-body newsec">
                      <h4>TRAVELLER DETAILS</h4>
                      
                      <div className="tabforsecion">	
                        {/* <ul className="nav nav-pills mb-3 tabforsecionwise" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                          <button className="nav-link active greenbtn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Home</button>
                          </li>
                          <li className="nav-item" role="presentation">
                          <button className="nav-link bluebtn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</button>
                          </li>
                        </ul> */}
                         {/* {isLCC && ( */}
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                      <h4>Choose Baggage</h4>
                    </div>
                    <div className="cardsection">
                      <div className="row">
                        <div className="col-lg-12 nbtext"></div>
                                  

{ssrbag && ssrbag.map((item, index) => (
 // item.Weight > 0 && (
    <div key={index} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/bag.png" alt="" style={{ width: "30px", height: "auto" }} /><br />
          <div style={{ marginTop: "5px" }}>
            <input
               type="radio"
               id={`radio-${index}`}
               name="selectedBag"
              checked={selectedItems[index] || false}
              onChange={() => handleItemSelect(index)}
              style={{ marginTop: '5px' }}
            /> &nbsp;&nbsp;&nbsp;
            <label htmlFor={`checkbox-${index}`}>
              {item.Weight} kg  ₹ {parseFloat(item.Price)}
            </label>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
 // )
))}
                                 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                      
                                  <label style={{marginLeft: '15px'}}>BAGGAGE PRICE(INR)</label>
                                  <input
                                        type="text"
                                        id="bagtotal" 
                                        readonly
                                        value={parseFloat(Number(totalBags))}
                                        style={{marginLeft: '15px',width:"100px",textAlign:"right",border: '1px solid #ddc8c8'}}
                                        
                                      />
                                      <button  className="btn btn-primary" 
                                      style={{marginLeft: '15px'}}
                                      onClick={submitSelectedItems}>Apply Additional Baggage</button>
                                      <br />
                       </div>
                    </div>
                  </div>
                </div>
 {/* )}*/}

{/* {isLCCIb && ( */}
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                      <h4>Choose Return Baggage</h4>
                    </div>
                    <div className="cardsection">
                      <div className="row">
                        <div className="col-lg-12 nbtext"></div>
                                  

{ssrbagIb && ssrbagIb.map((item, indexIb) => (
 // item.Weight > 0 && (
    <div key={indexIb} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/bag.png" alt="" style={{ width: "30px", height: "auto" }} /><br />
          <div style={{ marginTop: "5px" }}>
            <input
               type="radio"
               id={`radioIb-${indexIb}`}
               name="selectedBagIb"
              checked={selectedItemsIb[indexIb] || false}
              onChange={() => handleItemSelectIb(indexIb)}
              style={{ marginTop: '5px' }}
            /> &nbsp;&nbsp;&nbsp;
            <label htmlFor={`checkbox-${indexIb}`}>
              {item.Weight} kg  ₹ {parseFloat(item.Price)}
            </label>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
 // )
))}
                                 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                      
                                  <label style={{marginLeft: '15px'}}>BAGGAGE PRICE(INR)</label>
                                  <input
                                        type="text"
                                        id="bagtotalIb" 
                                        readonly
                                        value={parseFloat(Number(totalBagsIb))}
                                        style={{marginLeft: '15px',width:"100px",textAlign:"right",border: '1px solid #ddc8c8'}}
                                        
                                      />
                                      <button  className="btn btn-primary" 
                                      style={{marginLeft: '15px'}}
                                      onClick={submitSelectedItemsIb}>Apply Additional Baggage Return</button>
                                      <br />
                       </div>
                    </div>
                  </div>
                </div>
  {/* )}*/}

{/* {isLCC && ( */}
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                      <h4>Choose Meals</h4>
                    </div>
                    <div className="cardsection">
                      <div className="row"> 
                        <div className="col-lg-12 nbtext"></div> 
                        {ssrmeal && ssrmeal.map((items, index) => (
 // items.Code !== 'NoMeal' && (
    <div key={index} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/meals.jpg" style={{ width: "30px", height: "auto" }} alt="" />
        <label htmlFor={`checkbox-${index}`} style={{ marginLeft: '7px' }}>
            <span style={{ fontSize: '18px', color: "red" }}> ₹ {parseFloat(items.Price)}</span>
          </label>
          <label htmlFor={`checkbox-${index}`} style={{ marginLeft: '15px' }}>Qty: 1</label>
          </div>
     
          <div style={{ display: 'flex', alignItems: 'flex-start' }}> 
          <input
            type="radio"
            id={`radio-${index}`}
            name="mealcheck"
            checked={selectedMeals[index]}
            onChange={() => handleMealSelect(index)}
            style={{ marginTop: '5px' }}
          /> &nbsp;
          <label htmlFor={`checkbox-${index}`} style={{ marginLeft: '7px' }}>
            {items.AirlineDescription}({items.Code})
          </label>
          </div>
          <input
            type="hidden"
            id={`text-${index}`}
            value={ssrmeal[index].Quantity || 1} readonly="true"
            onChange={(e) => handleChangeNumber(index, parseInt(e.target.value, 10))}
            style={{ marginLeft: '15px', width: "20px", textAlign: "left", border: '1px solid #ddc8c8' }}
          />
        </div>
      </div>
      <br />
    </div>
  //)
))}
                                 
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-lg-12">
                      
                                  <label style={{marginLeft: '15px'}}>MEALS TOTAL(INR)</label>
                                  <input
                                        type="text"
                                        id="mealtotal"
                                        value={parseFloat(Number(totalMeal))}
                                        style={{marginLeft: '15px',width:"100px",textAlign:"right",border: '1px solid #ddc8c8'}}
                                        readonly
                                      />
                                      <button  className="btn btn-primary" 
                                      style={{marginLeft: '15px'}}
                                      onClick={submitSelectedMeals}>Apply Meals</button>
                       </div>
                    </div>
                    <br />
                  </div>
                </div>
{/* )}  */}

{/* {isLCCIb && ( */}
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                      <h4>Choose Return Meals</h4>
                    </div>
                    <div className="cardsection">
                      <div className="row"> 
                        <div className="col-lg-12 nbtext"></div> 
  {ssrmealIb && ssrmealIb.map((items, indexIb) => (
// items.Code !== 'NoMeal' && (
    <div key={indexIb} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/meals.jpg" style={{ width: "30px", height: "auto" }} alt="" />
        <label htmlFor={`checkbox-${indexIb}`} style={{ marginLeft: '7px' }}>
            <span style={{ fontSize: '18px', color: "red" }}> ₹ {parseFloat(items.Price)}</span>
          </label>
          <label htmlFor={`checkbox-${indexIb}`} style={{ marginLeft: '15px' }}>Qty: 1</label>
          </div>
     
          <div style={{ display: 'flex', alignItems: 'flex-start' }}> 
          <input
            type="radio"
            id={`radioIb-${indexIb}`}
            name="mealcheckIb"
            checked={selectedMealsIb[indexIb]}
            onChange={() => handleMealSelectIb(indexIb)}
            style={{ marginTop: '5px' }}
          /> &nbsp;
          <label htmlFor={`checkbox-${indexIb}`} style={{ marginLeft: '7px' }}>
            {items.AirlineDescription}({items.Code})
          </label>
          </div>
          <input
            type="hidden"
            id={`textIb-${indexIb}`}
            value={ssrmealIb[indexIb].Quantity || 1} readonly="true"
            onChange={(e) => handleChangeNumberIb(indexIb, parseInt(e.target.value, 10))}
            style={{ marginLeft: '15px', width: "20px", textAlign: "left", border: '1px solid #ddc8c8' }}
          />
        </div>
      </div>
      <br />
    </div>
 // )
))}
                                 
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-lg-12">
                      
                                  <label style={{marginLeft: '15px'}}>MEALS TOTAL(INR)</label>
                                  <input
                                        type="text"
                                        id="mealtotalIb"
                                        value={parseFloat(Number(totalMealIb))}
                                        style={{marginLeft: '15px',width:"100px",textAlign:"right",border: '1px solid #ddc8c8'}}
                                        readonly
                                      />
                                      <button  className="btn btn-primary" 
                                      style={{marginLeft: '15px'}}
                                      onClick={submitSelectedMealsIb}>Apply Return Meals</button>
                       </div>
                    </div>
                    <br />
                  </div>
                </div>
{/* )}  */}
                        <div className="alertbox">
                          <div className="iqMYlR">NOTE:</div>
                          <div>Please make sure you enter the Name as per your govt. photo id.</div>
                        </div>
                       <form  onSubmit={formik.handleSubmit}>
                        <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                           
                         
                          
                          
                            <div className="form-group discountdetails">
                              <div className="userview">
                               <i className="fa fa-phone" aria-hidden="true"></i> <span>Contact details</span>
                              </div>
                              </div>
                            <div className="form-group discountdetails">
                              <div className="row">
                                {/* <div className="col-lg-3">
                                <label></label>
                                </div> */}
                                <div className="col-lg-4">
                                  <label>Email Id</label>
                                <TextField
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="email"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>
                                <div className="col-lg-1">
                                <label>Code</label>
                                <TextField
                                        id="ccode"
                                        name="ccode"
                                        className="form-control"
                                        value={formik.values.ccode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        error={formik.touched.ccode && Boolean(formik.errors.ccode)}
                                        helperText={formik.touched.ccode && formik.errors.ccode}
                                    />
                                </div>
                                <div className="col-lg-3">
                                <label>Mobile Number</label>
                                <TextField
                                        id="mobile"
                                        name="mobile"
                                        className="form-control"
                                        value={formik.values.mobile}
                                        onChange={formik.handleChange}
                                        type="number"
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                        helperText={formik.touched.mobile && formik.errors.mobile}
                                    />
                                </div>
                                <div className="col-lg-3">
                                {renderDocumentIdInput()}
                                </div>
                              </div>
                            </div>
                            <div className="form-group discountdetails">
                              <div className="row">
                                
                                <div className="col-lg-2">
                                  <label>Country</label>
                                <TextField
                                        id="country"
                                        name="country"
                                        className="form-control"
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.country && Boolean(formik.errors.country)}
                                        helperText={formik.touched.country && formik.errors.country}
                                    />
                                </div>
                                <div className="col-lg-3">
                                <label>City</label>
                                <TextField
                                        id="city"
                                        name="city"
                                        className="form-control"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.city && Boolean(formik.errors.city)}
                                        helperText={formik.touched.city && formik.errors.city}
                                    />
                                </div>
                                <div className="col-lg-3">
                                <label>Address 1</label>
                                <TextField
                                        id="address1"
                                        name="address1"
                                        className="form-control"
                                        value={formik.values.address1}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                        helperText={formik.touched.address1 && formik.errors.address1}
                                    /> 
                                </div>
                                <div className="col-lg-3">
                                <label>Address 2</label>
                                <TextField
                                        id="address2"
                                        name="address2"
                                        className="form-control"
                                        value={formik.values.address2}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                        helperText={formik.touched.address2 && formik.errors.address2}
                                    />
                                </div>
                              </div>
                            </div>
                            <div  className="firstform">
               
              <div className="userview">
                <i className="fa fa-user" aria-hidden="true"></i> <span> {heading} </span>
              </div>
              <div className="form-group discountdetails">
                <div className="row">
                  {/* <div className="col-lg-3">
                    <label>Adult  Name And Details</label>
                  </div> */}
                  <div className="col-lg-1 p-0">
                  <label>Title</label>
                  <Select
                                        labelId="demo-simple-select-label"
                                        id="title"
                                        value={title}
                                        className="form-control"
                                        onChange={handleChange}
                                        style={{ width: '70px' }}
                                    >
                                        <MenuItem value="Mr">Mr</MenuItem><br />
                                        <MenuItem value="Mrs">Mrs</MenuItem><br />
                                        <MenuItem value="Miss">Miss</MenuItem><br />
                                        <MenuItem value="Mstr">Mstr</MenuItem>
                                    </Select> 
                  </div>
                  <div className="col-lg-3">
                  <label>First Name</label>
                  <TextField
                                            id="customerfName"
                                            name="customerfName"
                                            className="form-control"
                                            value={formik.values.customerfName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.customerfName && Boolean(formik.errors.customerfName)}
                                            helperText={formik.touched.customerfName && formik.errors.customerfName}
                                        />
                  </div>
                  <div className="col-lg-3">
                  <label>Last Name</label>
                  <TextField
                                            id="customerlName"
                                            name="customerlName"
                                            className="form-control"
                                            value={formik.values.customerlName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.customerlName && Boolean(formik.errors.customerlName)}
                                            helperText={formik.touched.customerlName && formik.errors.customerlName}
                                        />
                  </div>
                  
                  <div className="col-lg-3">
                  <label>Date of Birth</label>
                  {/* <TextField
                                        id="dob"
                                        name="dob"
                                        className="form-control"
                                        value={formik.values.dob}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="date"
                                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                                        helperText={formik.touched.dob && formik.errors.dob}
                                    />  */}
                  <DatePicker
                          id="dob"
                          name="dob"
                          selected={formik.values.dob}
                          onChange={(date) => formik.setFieldValue('dob', date)}
                          onBlur={formik.handleBlur}
                          dateFormat="dd/MM/yyyy"
                          maxDate={maxDt}
                          minDate={minDt}
                          showYearDropdown
                          yearDropdownItemNumber={dCount} // Set the number of years to show in the dropdown
                          placeholderText="Date of Birth"
                          inputProps={{ autoComplete: 'off' }}
                          wrapperClassName="date-picker-wrapper" 
                          style={{ height: '100px', border: '1px solid #3498db', borderRadius: '5px' }}
                        />
                        {formik.touched.dob && formik.errors.dob && (
                          <div className="error-message">{formik.errors.dob}</div>
                        )}
                  </div>
                
                </div>
                <div className="row">
                  {/* <div className="col-lg-3">
                    
                  </div> */}
                  <div className="col-lg-1 p-0">
                  <label>Nationality</label>
                  <TextField
                                        id="nationality"
                                        name="nationality"
                                        className="form-control"
                                        value={formik.values.nationality}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                                        helperText={formik.touched.nationality && formik.errors.nationality}
                                    />
                  </div>
                  <div className="col-lg-3">
                  <label>ID Proof/Passport Number</label>
                  <TextField
                                            id="ppnumber"
                                            name="ppnumber"
                                            className="form-control"
                                            value={formik.values.ppnumber}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.ppnumber && Boolean(formik.errors.ppnumber)}
                                            helperText={formik.touched.ppnumber && formik.errors.ppnumber}
                                        />
                  </div>
                  <div className="col-lg-3">
                  <label>Passport Expiry Date</label>
                  {/* <TextField
                                        id="expirydate"
                                        name="expirydate"
                                        className="form-control"
                                        value={formik.values.expirydate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="date"
                                        error={formik.touched.expirydate && Boolean(formik.errors.expirydate)}
                                        helperText={formik.touched.expirydate && formik.errors.expirydate}
                                    />  */}
                    <DatePicker
                      id="expirydate"
                      name="expirydate"
                      selected={formik.values.expirydate} // Set the selected date
                      onChange={(date) => formik.setFieldValue('expirydate', date)} // Set the formik field value
                      onBlur={formik.handleBlur}
                      dateFormat="dd/MM/yyyy" // Set the desired date format
                      maxDate={tenYearsAfter}
                      minDate={today}
                      showYearDropdown
                      yearDropdownItemNumber={10} // Set the number of years to show in the dropdown
                      placeholderText="Expiry date"
                      inputProps={{ autoComplete: 'off' }}
                      wrapperClassName="date-picker-wrapper" 
                      style={{ height: '100px', border: '1px solid #3498db', borderRadius: '5px' }}
                    />
                  </div>
                 <div className="col-lg-2">
                 
                 </div>
                
                </div>
              </div>
            

             
            </div>
            {isDivVisible && (
                  <div className="edit_profileSec" >
                        <div className="editProfileForm">
                            <div className="clearDiv row">
                               <table id="datatable" className="table dt-responsive table-bordered nowrap airlisttable" style={{borderCollapse: "collapse", borderSpacing: "0", width: "100%",textAlign:"center"}}>
                                    <thead  style={{backgroundColor: "rgb(223 226 228)",color:"#fff"}}>
                                       <th>Title</th>
                                       <th>First Name</th>
                                       <th>Last Name</th>
                                       <th>Date of Birth</th>
                                       <th>Passport Number</th>
                                       <th>Expiry Date</th>
                                       <th>Action</th>
                                    </thead>
                                    <tbody>
                                    {passengers.map((passenger, index) => (
                                        <tr key={index}>
                                        <td>{passenger.Title}</td>
                                        <td>{passenger.FirstName}</td>
                                        <td>{passenger.LastName}</td>
                                        <td>{moment(passenger.DateOfBirth).format('DD/MM/YYYY')}</td>
                                        <td>{passenger.PassportNo}</td>
                                        <td>{moment(passenger.PassportExpiry).format('DD/MM/YYYY')}</td>
                                        <td><a  href="javasript:void(0);" onClick={() => openModalR(passenger,index)}>Edit</a></td>
                                         </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>  
                    )} 

                            <div className="form-group text-end">
                              <div className="row">
                                <div className="col-lg-6">
                                  <p>Your ticket will be sent to this email address</p>
                                </div>
                                <div className="col-lg-3">
                 
                                <Button  type="button"  className="submitebtn"  variant="contained" color="info"  onClick={toggleDivVisibility} >
                                {isDivVisible ? 'Hide ' : 'View '} &nbsp;&nbsp;<i className="fa fa-user" aria-hidden="true"></i>
                                </Button>
                                </div>
                                <div className="col-lg-3 text-end">
                                <Button type="Submit" style={submitbtn} className="submitebtn"  variant="contained" color="primary">
                                        {btnhead}{formik.isSubmitting ? <CircularProgress size={24} /> : ' >>>' }
                                </Button>
                                 
                                </div>
                              </div>
                            </div>
                           
                          </div>
                          
                          
                        </div>
                 </form> 
                      </div>
                    </div>	
                  </div>	
                  
                  
                </div>
                

                



                {/* <div className="col-lg-12 thirdview detailsview">
                  <div className="card">
                    <div className="card-body  newsec">
                      <h4>ADDONS</h4>
                    </div>	
                  </div>	
                </div>
                
                <div className="col-lg-12 thirdview detailsview">
                  <div className="card">
                    <div className="card-body newsec">
                      <h4>PAYMENT DETAILS</h4>
                    </div>	
                  </div>	
                </div>
                 */}
                
              </div>

            </div>

            <div className="col-lg-3">
              <div className="asidesection bg-white">
                <div className="card">
                    <div className="card-body">
                      <h4>FARE SUMMARY </h4>
                      <p>{passString}	</p>
                    </div>
                      <div className="pricedetails">
                        <div className="price">
                          <table className="table pricelisttable bg-white table-borderless">
                          <tbody>
                          <tr>
                        <td>Base fare + Tax</td>
                        <td className="text-right">{parseFloat(basefare).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                      </tr>
                            <tr>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                        <td>Service Charge</td>
                        <td className="text-right">{parseFloat(servicefare/2).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                      </tr>
                      <tr>
                                                <td></td>
                                                <td></td>
                                              </tr> <tr>
                                              <td>Meals Charge</td>
                                          <td className="text-right">{parseFloat(totalMeal).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                                        </tr>
                                        <tr>
                                                <td></td>
                                                <td></td>
                                              </tr> <tr>
                                              <td>Baggage Charge</td>
                                          <td className="text-right">{parseFloat(totalBags).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                                        </tr>
                                        <tr>
                                                <td></td>
                                                <td></td>
                                              </tr>   
                                              <tr>
                                                <td><h5>Total</h5></td>
                                                <td  className="text-right"><h5>{(parseFloat(totalfare)+parseFloat(totalBags)+parseFloat(totalMeal)).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</h5></td>
                                              </tr>
                                            </tbody>
                                            </table>
                        </div>
                      </div>
                </div>
                <div className="card">
                    <div className="card-body">
                      <h4>RETURN FARE SUMMARY </h4>
                      <p>{passString}	</p>
                    </div>
                      <div className="pricedetails">
                        <div className="price">
                          <table className="table pricelisttable bg-white table-borderless">
                          <tbody>
                          <tr>
                        <td>Base fare + Tax</td>
                        <td className="text-right">{parseFloat(basefareIb).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                      </tr>
                            <tr>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                        <td>Service Charge</td>
                        <td className="text-right">{parseFloat(servicefare/2).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                      </tr>
                      <tr>
                                                <td></td>
                                                <td></td>
                                              </tr> <tr>
                                              <td>Meals Charge</td>
                                          <td className="text-right">{parseFloat(totalMealIb).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                                        </tr>
                                        <tr>
                                                <td></td>
                                                <td></td>
                                              </tr> <tr>
                                              <td>Baggage Charge</td>
                                          <td className="text-right">{parseFloat(totalBagsIb).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</td>
                                        </tr>
                                        <tr>
                                                <td></td>
                                                <td></td>
                                              </tr>   
                                              <tr>
                                                <td><h5>Total</h5></td>
                                                <td  className="text-right"><h5>{(parseFloat(totalfareIb)+parseFloat(totalBagsIb)+parseFloat(totalMealIb)).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</h5></td>
                                              </tr>
                            <tr>
                              <td><h5>Grand Total<br /><span style={{fontSize:"12px"}}>({(parseFloat(totalfare)+parseFloat(totalBags)+parseFloat(totalMeal)).toFixed(2)} + {(parseFloat(totalfareIb)+parseFloat(totalBagsIb)+parseFloat(totalMealIb)).toFixed(2)})</span></h5></td>
                              <td  className="text-right"><h5>{(parseFloat(totalfare)+parseFloat(totalBags)+parseFloat(totalMeal)+parseFloat(totalfareIb)+parseFloat(totalBagsIb)+parseFloat(totalMealIb)).toLocaleString('en-IN', {style: 'currency',currency: 'INR'})}</h5></td>
                            </tr>
                          </tbody>
                          </table>
                        </div>
                      </div>
                </div>                     
{/* 
                <div className="card mt-4">
                  <div className="card-body">
                    <h4>TRAVEXPRO OFFERS</h4>
                  </div>
                  <div className="pricedetails">
                    <div className="price">
                      <table className="table pricelisttable bg-white table-borderless">
                      <tbody>
                        <tr>
                          <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                          <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                          <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                        </tr>
                        <tr>
                          <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                          <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                          <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                        </tr>
                        <tr>
                          <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                          <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                          <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                        </tr>
                        
                      </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card mt-4">
                  <div className="card-body discount">
                    <h4>Apply Discount</h4>
                    <p> Have a discount / promo code to redeem</p>
                  </div>
                  <div className="discountdetails">
                    <div className="row">
                      <div className="col-lg-9">
                        <input type="text" className="form-control" placeholder="Promo Code" name="discount" />
                      </div>
                      <div className="col-lg-3">
                        <button className="btn btnapply">Apply</button>
                      </div>	
                    </div>	
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          
        </div>
		
        </section>
        <footer className="section footer-classNameic">
        <div className="container">
          
          <div className="row row-50">
            <div className="col-lg-5 col-sm-6 wow fadeInUp brandlogo">
             <a className="brand" href="index.html"><img className="brand-logo-light" src="images/logo.png" alt="" width="200" height="60" /></a>
            </div>
            <div className="col-lg-4 col-sm-6 d-sm-flex flex-column wow fadeInUp">
              <h6 className="heading-6">useful links</h6>
			     <div className="row">
					<div className="col-lg-6 col-md-6 col-sm-6 ">
					  <ul className="list-marked-2">
						<li><a href="#">Flights</a></li>
						<li><a href="#">International Flights</a></li>
						<li><a href="#">Hotels</a></li>
						<li><a href="#">International Hotels</a></li>
						<li><a href="#">Domestic Airline</a></li>
					  </ul>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-6 ">
					  <ul className="list-marked-2">
						<li><a href="#">Indigo Airline</a></li>
						<li><a href="#">Air Asia</a></li>
						<li><a href="#">Spicejet</a></li>
						<li><a href="#">Go Air</a></li>
						<li><a href="#">Air India</a></li>
					  </ul>
					</div>
				</div>
            </div>
           </div>
        </div> 
        {/* <!--+rights--> */}
       </footer> 
       {showModal && (
        <div  className="modal"
        style={{
          display: 'flex',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div  className="modal-content" 
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px', 
          }}
        >
         <div className="modal-header">
        <h5 className="modal-title">Edit Passenger Information-{editedPassType}</h5>
        <button type="button"  onClick={onClose}  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
         <div className="modal-body">
        
        <div className="row">
            <div className="col-lg-6 form-group" >
            <label>Title : 
            <input type="hidden" id="pxtype" value={editedPaxType} />
            </label>
            </div>
            
            <div className="col-lg-4 form-group" >
            <select type="text" value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)} >
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Mstr">Mstr</option>
            </select>
            </div>
         </div>   
         <div className="row">
            <div className="col-lg-6 form-group" >
            <label>First Name : 
            </label>
            </div>
            <div className="col-lg-4 form-group" >
            <input type="text" value={editedFirstName} onChange={(e) => setEditedFirstName(e.target.value)} />
       
            </div>
         </div>
         <div className="row">
            <div className="col-lg-6 form-group" >
            <label>Last Name : 
            </label>
            </div>
            <div className="col-lg-4 form-group" >
            <input type="text" value={editedLastName} onChange={(e) => setEditedLastName(e.target.value)} />
            </div>
         </div>
         <div className="row">
            <div className="col-lg-6 form-group" >
            <label>Date of Birth: 
            </label>
            </div>
            <div className="col-lg-4 form-group" >
            {/* <input type="date" value={editedDateOfBirth} onChange={(e) => setEditedDateOfBirth(e.target.value)} /> */}
            <DatePicker
              value={moment(editedDateOfBirth).format('DD/MM/YYYY')}
              onChange={setEditedDateOfBirthFun}
              dateFormat="dd/MM/yyyy"
              maxDate={maxDt}
              minDate={minDt}
              showYearDropdown
              yearDropdownItemNumber={dCount} // Set the number of years to show in the dropdown
              placeholderText="Date of Birth"
              inputProps={{ autoComplete: 'off' }}
              wrapperClassName="date-picker-wrapper" 
            />
         </div>
         </div>
         <div className="row">
            <div className="col-lg-6 form-group" >
            <label>Passport/ID Proof Number:
            </label>
            </div>
            <div className="col-lg-4 form-group" >
            <input type="text" value={editedPPNumber} onChange={(e) => setEditedPPNumber(e.target.value)} />
         </div>
         </div>
         <div className="row">
            <div className="col-lg-6 form-group" >
            <label>Passport Exopire Date : 
            </label>
            </div>
            <div className="col-lg-4 form-group" >
            <DatePicker
              value={moment(editedPPExpireDate).format('DD/MM/YYYY')}
              onChange={setEditedPPExpireDateFun}
              dateFormat="dd/MM/yyyy"
              maxDate={tenYearsAfter}
              minDate={today}
              showYearDropdown
              yearDropdownItemNumber={10} // Set the number of years to show in the dropdown
              placeholderText="Expire Date"
              inputProps={{ autoComplete: 'off' }}
              wrapperClassName="date-picker-wrapper" 
            />
            {/* <input type="date" value={editedPPExpireDate} onChange={(e) => setEditedPPExpireDate(e.target.value)} /> */}
         </div>
         </div>
         {/* {editedMealDynamic.map((meal, index) => (
  <p key={index}>{meal.AirlineDescription}</p>
))} */}
        
{/* {isLCC && ( */}
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                    <button onClick={handleMealsClick} className="btn btn-secondary">Choose Meals</button>
                    </div>
                  
                    {showMeals && (
                    <div className="cardsection">
                      <div className="row"> 
                        <div className="col-lg-12 nbtext"></div> 
                        {ssrmeal && ssrmeal.map((items, indexmeal) => (
  //items.Code !== 'NoMeal' && (
    <div key={indexmeal} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/meals.jpg" style={{ width: "30px", height: "auto" }} alt="" />
        <label htmlFor={`checkbox-${indexmeal}`} style={{ marginLeft: '7px' }}>
            <span style={{ fontSize: '18px', color: "red" }}> ₹ {parseFloat(items.Price)}</span>
          </label>
          <label htmlFor={`checkbox-${indexmeal}`} style={{ marginLeft: '15px' }}>Qty: 1</label>
          </div>
     
          <div style={{ display: 'flex', alignItems: 'flex-start' }}> 
          <input
            type="radio"
            id={`radio1-${indexmeal}`}
            name="mealcheck"
            checked={editedMealDynamic.some(meal => meal.AirlineDescription === items.AirlineDescription)}
            onChange={() => handleMealSelectEdit(indexmeal)}
            style={{ marginTop: '5px' }}
          /> &nbsp;
          <label htmlFor={`checkbox-${indexmeal}`} style={{ marginLeft: '7px' }}>
            {items.AirlineDescription}({items.Code})
          </label>
          </div>
          <input
            type="hidden"
            id={`text-${indexmeal}`}
            value={ssrmeal[indexmeal].Quantity || 1} readonly="true"
            onChange={(e) => handleChangeNumber(indexmeal, parseInt(e.target.value, 10))}
            style={{ marginLeft: '15px', width: "20px", textAlign: "left", border: '1px solid #ddc8c8' }}
          />
        </div>
      </div>
      <br />
    </div>
  //)
))}
                    <div className="row">
                      <div className="col-lg-12 d-flex justify-content-end">
                                      <button  className="btn btn-danger" 
                                      style={{marginLeft: '15px'}}
                                      onClick={handleMealCancel}>Cancel Meal</button>
                       </div>
                    </div>  
                                 
                      </div>
                      </div>
                      )}
                
                  </div>
                </div>
{/*)}*/}
 <h5>Return Meals</h5>
 {isLCCIb && (
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                    <button onClick={handleMealsClickIb} className="btn btn-secondary">Choose Return Meals</button>
                    </div>
                  
                    {showMealsIb && (
                    <div className="cardsection">
                      <div className="row"> 
                        <div className="col-lg-12 nbtext"></div> 
                        {ssrmealIb && ssrmealIb.map((items, indexmealIb) => (
  //items.Code !== 'NoMeal' && (
    <div key={indexmealIb} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/meals.jpg" style={{ width: "30px", height: "auto" }} alt="" />
        <label htmlFor={`checkbox-${indexmealIb}`} style={{ marginLeft: '7px' }}>
            <span style={{ fontSize: '18px', color: "red" }}> ₹ {parseFloat(items.Price)}</span>
          </label>
          <label htmlFor={`checkbox-${indexmealIb}`} style={{ marginLeft: '15px' }}>Qty: 1</label>
          </div>
     
          <div style={{ display: 'flex', alignItems: 'flex-start' }}> 
          <input
            type="radio"
            id={`radio1Ib-${indexmealIb}`}
            name="mealcheckIb"
            checked={editedMealDynamicIb.some(meal => meal.AirlineDescription === items.AirlineDescription)}
            onChange={() => handleMealSelectEditIb(indexmealIb)}
            style={{ marginTop: '5px' }}
          /> &nbsp;
          <label htmlFor={`checkbox-${indexmealIb}`} style={{ marginLeft: '7px' }}>
            {items.AirlineDescription}({items.Code})
          </label>
          </div>
          <input
            type="hidden"
            id={`textIb-${indexmealIb}`}
            value={ssrmealIb[indexmealIb].Quantity || 1} readonly="true"
            onChange={(e) => handleChangeNumberIb(indexmealIb, parseInt(e.target.value, 10))}
            style={{ marginLeft: '15px', width: "20px", textAlign: "left", border: '1px solid #ddc8c8' }}
          />
        </div>
      </div>
      <br />
    </div>
 // )
))}
                    <div className="row">
                      <div className="col-lg-12 d-flex justify-content-end">
                                      <button  className="btn btn-danger" 
                                      style={{marginLeft: '15px'}}
                                      onClick={handleMealCancelIb}>Cancel Return Meal</button>
                       </div>
                    </div>  
                                 
                      </div>
                      </div>
                      )}
                
                  </div>
                </div>
)}
 
 {isLCC && (
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                    <button onClick={handleBaggageClick}  className="btn btn-secondary">Choose Baggage</button>
                    </div>
                    {showBaggage && (
                    <div className="cardsection">
                      <div className="row">
                        <div className="col-lg-12 nbtext"></div>
                                  

{ssrbag && ssrbag.map((item, indexbag) => (
 // item.Weight > 0 && (
    <div key={indexbag} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/bag.png" alt="" style={{ width: "30px", height: "auto" }} /><br />
          <div style={{ marginTop: "5px" }}>
            <input
               type="radio"
               id={`radio-${indexbag}`}
               name="selectedBag"
              checked={editedBagDynamic.some(bag => bag.Weight === item.Weight)}
              onChange={() => handleItemSelectEdit(indexbag)}
            /> &nbsp;&nbsp;&nbsp;
            <label htmlFor={`checkbox-${indexbag}`}>
              {item.Weight} kg  ₹ {parseFloat(item.Price)}
            </label>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  //)
))}
      
                  <div className="row">
                      <div className="col-lg-12 d-flex justify-content-end">
                                      <button  className="btn btn-danger" 
                                      style={{marginLeft: '15px'}}
                                      onClick={handleBagCancel}>Cancel Baggage</button>
                       </div>
                    </div>                          
                      </div>
                    </div>
         )}
                
                  </div>
                </div>
  )}  
  <h5>Return Baggage</h5> 
  {isLCCIb && (
                <div className="col-lg-12 mt-5 detailsview">
                  <div className="card">
                    <div className="card-body infobox">
                    <button onClick={handleBaggageClickIb}  className="btn btn-secondary">Choose Return Baggage</button>
                    </div>
                    {showBaggageIb && (
                    <div className="cardsection">
                      <div className="row">
                        <div className="col-lg-12 nbtext"></div>
                                  

{ssrbagIb && ssrbagIb.map((item, indexbagIb) => (
  //item.Weight > 0 && (
    <div key={indexbagIb} className="col-md-4">
      <div className="row">
        
        <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}> 
        <img src="images/bag.png" alt="" style={{ width: "30px", height: "auto" }} /><br />
          <div style={{ marginTop: "5px" }}>
            <input
               type="radio"
               id={`radioIb-${indexbagIb}`}
               name="selectedBagIb"
              checked={editedBagDynamicIb.some(bag => bag.Weight === item.Weight)}
              onChange={() => handleItemSelectEditIb(indexbagIb)}
            /> &nbsp;&nbsp;&nbsp;
            <label htmlFor={`checkbox-${indexbagIb}`}>
              {item.Weight} kg  ₹ {parseFloat(item.Price)}
            </label>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
 // )
))}
      
                  <div className="row">
                      <div className="col-lg-12 d-flex justify-content-end">
                                      <button  className="btn btn-danger" 
                                      style={{marginLeft: '15px'}}
                                      onClick={handleBagCancelIb}>Cancel Return Baggage</button>
                       </div>
                    </div>                          
                      </div>
                    </div>
         )}
                
                  </div>
                </div>
  )}  
      <div className="row">
            <div className="col-lg-8 form-group" >
            </div>
            <div className="col-lg-2 form-group" >
            <button onClick={handleSave} className="btn w-100 btn-success">Save</button>
            </div>
            <div className="col-lg-2 form-group" >
            <button onClick={onClose} className="btn w-100 btn-secondary">Close</button>
          </div>
      </div>
      <br /><br />
          </div>
        </div></div></div>
      )}
        </div>
  )
}


export default CustomerInfoIBOB